import request from "@/api/request";

export function getParticipantGroup(id) {
    return request({
        url: `/participant-groups/${id}`,
        method: 'get'
    })
}

export function deleteParticipantGroup(id) {
    return request({
        url: `/participant-groups/${id}`,
        method: 'delete'
    })
}

export function updateParticipantGroup(data) {
    return request({
        url: `/participant-groups`,
        method: 'put',
        data: data
    })
}

export function createParticipantGroup(data) {
    return request({
        url: `/participant-groups`,
        method: 'post',
        data: data
    })
}

export function getParticipantGroupAlarmPlans(id, search = '') {
    const data = {
        type: 'PARTICIPANT_GROUP',
        objectId: id,
        referenceType: 'ALARMPLAN',
        currentPage: 0,
        filterValue: search,
        pageSize: 10000
    }

    return request({
        url: `/list/membership`,
        method: 'post',
        data: data
    })
}

export function getParticipantGroupAlarmPoints(id, search = '') {
    const data = {
        type: 'PARTICIPANT_GROUP',
        objectId: id,
        referenceType: 'ALARMPOINT',
        currentPage: 0,
        filterValue: search,
        pageSize: 10000
    }

    return request({
        url: `/list/membership`,
        method: 'post',
        data: data
    })
}

