<template>
  <Toast/>
  <div class="p-grid">
    <div style="margin: 10px;box-shadow: 1px 1px 1px lightgrey; font-size: 12px; background: #fff"
         :style="expandSidebarDialog ? 'width:75rem;' : 'width:20rem;'"
    >
      <div style="padding-right: 1.5rem;padding-top: 1.5rem;padding-left: 1.5rem;">
        <h3 class="p-m-0">
          <Button
              v-tooltip="expandSidebarDialog ? _t('label_List_view') : _t('label_Table_view')"
              class="p-button-rounded p-button-icon-only p-button-text"
              :icon="expandSidebarDialog ? 'mdi mdi-table-arrow-left' : 'mdi mdi-table-arrow-right'"
              style="float: right;"
              @click="expandSidebarDialog = !expandSidebarDialog"
          />
          <Button v-if="!isEditable" v-tooltip.bottom="_t('label_add_new')"
                  class="p-button-rounded p-button-success p-button-text"
                  icon="pi pi-plus"
                  style="float: right;" @click="initNewParticipantDialog()"/>
          <Button style="float: right;" class="p-button-rounded p-button-icon-only p-button-text"
                  :icon="childFilter ? 'medium-icon mdi mdi-account-multiple-minus' : 'medium-icon mdi mdi-account-multiple-plus'"
                  @click="childFilter = !childFilter"
                  v-tooltip="childFilter ? _t('label_exclude_child_orgs') : _t('label_include_child_orgs')"/>
          <strong>{{ _t('label_participants') }}</strong>
        </h3>

        <MultiSelectableBreadcrumb
            ref="breadcrumbData"
            :tree-params="treeParams"
            single-selection
            @change="getUserGroups()"
        />
      </div>
      <div>
          <span v-if="!listFiltersEnabled" :style="expandSidebarDialog ? 'width:45rem;' : 'width:15rem;'"
                class="p-input-icon-right p-m-3">
            <i class="pi pi-search" style="font-size: 12px;"/>
            <InputText v-model="participantFilterValue" style="width:100%;" type="text"/>
          </span>
        <Button
            :style="`position: relative; z-index: 1; top: ${!listFiltersEnabled ? '0.9rem;' : '1rem;'}; float: right; margin-right: .6rem;`"
            class="p-button-rounded p-button-text p-button-secondary"
            icon="pi pi-filter"
            @click="filtersDialog = true"
        />
      </div>
      <div v-if="listFiltersEnabled" style="padding: 5px 25px;">
        <span v-if="listFilters.name" style="color: #82B541;">{{ _t('label_name') }} {{ listFilters.name }}<br/></span>
        <span v-if="listFilters.surname" style="color: #82B541;">{{ _t('label_surname') }} {{
            listFilters.surname
          }}<br/></span>
        <span v-if="listFilters.shortName" style="color: #82B541;">{{ _t('label_short_name') }} {{
            listFilters.shortName
          }}<br/></span>
        <span v-if="listFilters.description" style="color: #82B541;">{{
            _t('label_description')
          }} {{ listFilters.description }}<br/></span>
        <span v-if="listFilters.login" style="color: #82B541;">{{ _t('label_login') }} {{
            listFilters.login
          }}<br/></span>
        <span v-if="listFilters.hasSchedule" style="color: #82B541;">{{
            _t('label_Schedules.filterPart')
          }} {{ listFilters.hasSchedule }}<br/></span>
        <span v-if="listFilters.ackCode" style="color: #82B541;">{{
            _t('label_Acknowledgments_code')
          }} {{ listFilters.ackCode }}<br/></span>
        <span v-if="listFilters.personalNumber" style="color: #82B541;">{{
            _t('label_personal_num')
          }} {{ listFilters.personalNumber }}<br/></span>
        <span v-if="listFilters.function" style="color: #82B541;">{{ _t('label_Function') }} {{
            listFilters.function
          }}<br/></span>
        <span v-if="listFilters.excdlueAlarmsByType" style="color: #82B541;">{{ _t('label_Excluded_alarms_by_type') }} {{
            listFilters.excdlueAlarmsByType
          }}<br/></span>
        <span v-if="listFilters.assignedToGroup" style="color: #82B541;">{{
            _t('label_Assigned_to_a_group')
          }} {{ listFilters.assignedToGroup }}<br/></span>
        <span v-if="listFilters.assignedToAlarmPlan" style="color: #82B541;">{{
            _t('label_Assigned_to_a_plan')
          }} {{ listFilters.assignedToAlarmPlan }}<br/></span>
        <span v-if="listFilters.hasDesktopAccess" style="color: #82B541;">{{
            _t('label_Has_desktop_access')
          }} {{ listFilters.hasDesktopAccess }}<br/></span>
        <span v-if="listFilters.hasAppAccess" style="color: #82B541;">{{
            _t('label_Has_app_access')
          }} {{ listFilters.hasAppAccess }}<br/></span>
      </div>
      <div>

      </div>
      <DataTable
          ref="tree"
          v-model:selection="selectedParticipantItem"
          :dataKey="tableDataKey"
          :lazy="true"
          :page="currentPage"
          :paginator="true"
          :paginatorTemplate="'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'"
          :rowHover="true"
          :rows="participantTableRows"
          :scrollable="true"
          :totalRecords="totalRecords"
          :value="participants"
          class="tight"
          scrollHeight="76vh"
          selectionMode="single"
          stripedRows
          @page="selectTablePage($event)"
          @row-click="this.selectParticipant($event.data.id);"
      >
        <Column :expander="true"
                field="name"
                :header="_t(expandSidebarDialog ? 'label_name' : 'label_full_name')"
                :style="'width: ' + (100 / alarmParticipantsValues.length) + '%; word-break: break-all; min-height: 38px;'"
        >
          <template #body="{data}">
               <span class="wrap"
                     v-if="expandSidebarDialog"
                     style="word-break: break-all; padding-left: 5px;">
              {{ data.name }}
               </span>
            <span class="wrap"
                  v-else
                  style="word-break: break-all; padding-left: 5px;">
              {{ data.surname }} {{ data.name }}
               </span>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog"
                field="surname"
                :header="_t('label_surname')"
                :hidden="!handleCheckRow('surname')"
                :style="'width: ' + (100 / alarmParticipantsValues.length) + '%; word-break: break-all; min-height: 38px;'"
        >
          <template #body="{data}">
               <span class="wrap"
                     style="word-break: break-all; padding-left: 5px;">
              {{ data.surname }}
               </span>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog"
                field="shortName"
                :header="_t('label_short_name')"
                :hidden="!handleCheckRow('shortName')"
                :style="'width: ' + (100 / alarmParticipantsValues.length)+ '%; word-break: break-all;min-height: 38px;'">
          <template #body="{data}">
              <span
                  style="overflow: hidden; text-overflow: ellipsis; padding-left: 5px;"
              >
              {{ data.shortName }}
              </span>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog"
                field="id"
                header="ID"
                :hidden="!handleCheckRow('id')"
                :style="'width: ' + (100 / alarmParticipantsValues.length)+ '%; word-break: break-all;min-height: 38px;'"
        >
          <template #body="{data}">
              <span
                  style="overflow: hidden; text-overflow: ellipsis; padding-left: 5px;"
              >
              {{ data.id }}
              </span>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog"
                field="Organization"
                :header="_t('ORGANIZATION')"
                :hidden="!handleCheckRow('orgName')"
                :style="'width: ' + (100 / alarmParticipantsValues.length)+ '%; word-break: break-all;min-height: 38px;'"
        >
          <template #body="{data}">
              <span
                  style="overflow: hidden; text-overflow: ellipsis; padding-left: 5px;"
              >
              {{ data.orgName }}
              </span>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog"
                field="media"
                :header="_t('label_media')"
                :hidden="!handleCheckRow('media')"
                :style="'width: ' + (100 / alarmParticipantsValues.length)+ '%; word-break: break-all;min-height: 38px;'"
        >
          <template #body="{data}">
            <div style="overflow: hidden; text-overflow: ellipsis; padding-left: 5px;">
              <span
                  v-for="(member, idx) in data.callingDeviceTypes" :key="member + idx">
              {{ member }} {{ idx !== data.callingDeviceTypes.length - 1 ? ', ' : '' }}
              </span>
            </div>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog"
                field="teamMediaSchedules"
                :header="_t('menu.team_media_schedules')"
                :hidden="!handleCheckRow('teamMediaSchedules')"
                :style="'width: ' + (100 / alarmParticipantsValues.length)+ '%; word-break: break-all;min-height: 38px;'"
        >
          <template #body="{data}">
              <span
                  style="overflow: hidden; text-overflow: ellipsis; padding-left: 5px;"
              >
              {{ data.rotationalCalendarName }}
              </span>
          </template>
        </Column>
      </DataTable>
    </div>
    <transition name="slide-fade">
      <Card v-show="selectedParticipant.id" class="p-col" style="overflow-x: scroll;">
        <template #title>
          <div class="p-d-flex p-jc-between">
            <div>
              <h3 class="p-m-0"><strong>{{ selectedParticipant.name + ' ' + selectedParticipant.surname }}</strong></h3>
            </div>
            <div>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Cancel')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-times"
                      @click="isEditable = false; selectParticipant(selectedParticipant.id)"/>
              <Button
                  v-tooltip.bottom="isEditable ? _t('Save'): _t('Edit')"
                  :class="isEditable ? 'p-button-rounded p-button-text p-button-success' : 'p-button-rounded p-button-text p-button-secondary' "
                  :disabled="!dataTabs"
                  :icon="isEditable ? 'pi pi-check' : 'pi pi-pencil'"
                  @click=" isEditable ? saveParticipant(): editParticipant()"/>

              <Button v-if="!isEditable" v-tooltip.bottom="_t('Clone')"
                      class="p-button-rounded p-button-text p-button-info"
                      icon="pi pi-copy"
                      @click="initNewParticipantDialog(true)"
              />
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Delete')"
                      class="p-button-rounded p-button-text p-button-danger"
                      icon="pi pi-trash"
                      @click="deleteParticipantDialog = true"
              />
            </div>
          </div>
        </template>
        <template #content>
          <TabView :disabled="selectedParticipant.id === null" v-model:activeIndex="activeTab">
            <TabPanel
                :disabled="selectedParticipant.id === null"
                :header="_t('menu.general')"
            >

              <div class="p-grid">
                <div :class="`style p-col-2 stripedBg`" style="vertical-align: middle;">
                  <p>{{ _t('label_name') }}</p>
                </div>
                <div :class="`style p-col-10 stripedBg`" style="vertical-align: middle;">
                  <p v-if="!isEditable">
                    <strong>{{ selectedParticipant.name ?? '...' }}</strong>
                  </p>
                  <InputText v-else v-model="selectedParticipant.name" class="p-md-12" maxlength="255" type="text"/>
                </div>

                <div :class="`style p-col-2`" style="vertical-align: middle;">
                  <p>{{ _t('label_surname') }}</p>
                </div>
                <div :class="`style p-col-10 `" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedParticipant.surname ?? '...' }}</strong></p>
                  <InputText v-else v-model="selectedParticipant.surname" class="p-md-12" maxlength="255" type="text"/>
                </div>

                <div :class="`style p-col-2 stripedBg`" style="vertical-align: middle;">
                  <p>{{ _t('label_short_name') }}</p>
                </div>
                <div :class="`style p-col-10 stripedBg`" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedParticipant.shortName ?? '...' }}</strong></p>
                  <InputText v-else v-model="selectedParticipant.shortName" class="p-md-12" maxlength="255"
                             type="text"/>
                </div>

                <div :class="`style p-col-2`" style="vertical-align: middle;">
                  <p>{{ _t('label_personal_id') }}</p>
                </div>
                <div :class="`style p-col-10 `" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedParticipant.personalNumber ?? '...' }}</strong></p>
                  <InputText v-else v-model="selectedParticipant.personalNumber" class="p-md-12" maxlength="255"
                             type="text"/>
                </div>

                <div :class="`style p-col-2 stripedBg`" style="vertical-align: middle;">
                  <p>{{ _t('label_language') }}</p>
                </div>
                <div :class="`style p-col-10 stripedBg`" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedParticipant.language }}</strong></p>
                  <Dropdown
                      v-else v-model="selectedParticipant.language"
                      :options="languages"
                      class="p-md-12"
                      optionLabel="label"
                      optionValue="value"
                      style="padding: 0;"
                  />
                </div>
                <div :class="`style p-col-2`" style="vertical-align: middle;">
                  <p>{{ _t('label_accept_code') }}</p>
                </div>
                <div :class="`style p-col-10 `" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedParticipant.pinCode ?? '...' }}</strong></p>
                  <InputText v-else v-model="selectedParticipant.pinCode" class="p-md-12" maxlength="255" type="text"/>
                </div>

                <div :class="`style p-col-2 stripedBg`" style="vertical-align: middle;">
                  <p>{{ _t('label_function') }}</p>
                </div>
                <div :class="`style p-col-10 stripedBg`" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedParticipant.function ?? '...' }}</strong></p>
                  <InputText v-else v-model="selectedParticipant.function" class="p-md-12" maxlength="255" type="text"/>
                </div>

                <div :class="`style p-col-2`" style="vertical-align: middle;">
                  <p>{{ _t('label_description') }}</p>
                </div>
                <div :class="`style p-col-10 `" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedParticipant.description ?? '...' }}</strong></p>
                  <Textarea v-else v-model="selectedParticipant.description" class="p-md-12" maxlength="512"
                            type="text"/>
                </div>

                <div :class="`style p-col-2 stripedBg`" style="vertical-align: middle;">
                  <p>{{ _t('label_organization') }}</p>
                </div>
                <div :class="`style p-col-10 stripedBg`" style="vertical-align: middle;">
                  <OrganizationLink ref="orgLink" v-model="selectedParticipant" :editMode="isEditable"
                                    modelType="PARTICIPANT"/>
                </div>
              </div>
            </TabPanel>
            <TabPanel
                :header="_t('label_media')"
            >
              <DataTable
                  :lazy="false"
                  :rows="10"
                  :value="selectedParticipant.media"
                  dataKey="id"
                  responsiveLayout="scroll"
                  stripedRows
              >

                <template #empty>
                  {{ _t('label_no') }} {{ _t('label_media') }}
                </template>
                <template #loading>
                  {{ _t('label_loading') }}
                </template>
                <Column :header="_t('label_active')" field="active" style="text-align: center">
                  <template #body="{data}">
                    <p v-if="!isEditable">{{ data.active ? _t('label_yes') : _t('label_no') }}</p>
                    <Checkbox v-else v-model="data.active" :binary="true" :disabled="!isEditable"/>
                  </template>
                </Column>
                <Column :header="_t('label_call_device')" field="message"
                        style="text-align: left; min-width: 13rem;">
                  <template #body="{data}">
                    <p v-if="!isEditable">
                      <SelectedOption v-model="data.callingDeviceType" :default="data.message"
                                      :options="mediaDeviceTypes"
                                      optionLabel="message" optionValue="message"/>
                    </p>
                    <Dropdown v-else v-model="data.message"
                              :options="mediaDeviceTypes"
                              :showClear="true"
                              dataKey="message"
                              optionLabel="message"
                              optionValue="message"
                              style="width:13rem; min-height: 30px;"
                              @change="mediaDeviceTypesChange($event, data)"
                    >
                      <template #value="slotProps">
                        <span style="text-transform: initial;">{{ slotProps.value }} </span>
                      </template>
                      <template #option="slotProps">
                        <span>
                          {{ slotProps.option.message }}
                        </span>
                      </template>
                    </Dropdown>
                  </template>
                </Column>
                <Column :header="_t('label_call_number')" field="callingNumber"
                        style="text-align: left; min-width: 15rem;">
                  <template #body="{data}">
                    <p v-if="!isEditable">{{ data.callingNumber }}</p>
                    <InputText v-else v-model="data.callingNumber"
                               v-no-autocomplete
                               :disabled="!isEditable"
                               :model-value="data.callingDeviceType == 'IOS_APP' && (!data.callingNumber || ! data.callingNumber.length) ? selectedParticipant.login : data.callingNumber"
                               maxlength="255"
                               style="width:15rem" type="text"
                    />
                  </template>
                </Column>
                <Column :header="_t('label_ack_time')" field="acknowledgeTime" style="text-align: center">
                  <template #body="{data}">
                    <p v-if="!isEditable">{{ data.acknowledgeTime }}</p>
                    <InputNumber v-else v-model="data.acknowledgeTime" :disabled="!isEditable" :max="99999999999"
                                 :min="1" showButtons style="float: left;"/>
                  </template>
                </Column>
                <Column :header="_t('label_rank')" field="rank" style="text-align: center">
                  <template #body="{data}">
                    <p v-if="!isEditable">{{ data.rank }}</p>
                    <InputNumber v-else v-model="data.rank" :disabled="!isEditable" :max="99999999999" :min="1"
                                 showButtons style="float: left;"/>
                  </template>
                </Column>
                <Column header="" style="text-align: center">
                  <template v-if="isEditable" #body="slotProps">
                    <Button class="p-button-text p-button-plain p-button-lg" @click="removeMedia(slotProps)">
                      <i class="pi pi-trash"></i>
                    </Button>
                  </template>
                </Column>
              </DataTable>
              <Button v-if="isEditable" class="p-button-success" @click="addNewMediaItem()">{{ _t('label_add_new') }}
              </Button>
            </TabPanel>
            <TabPanel :header="_t('label_Media_schedules')">
              <div class="p-grid">
                <div class="p-col-12 stripedBg p-py-2" style="vertical-align: middle;">
                  <div>
                    <i class="pi pi-calendar"></i> <strong>{{ _t('PARTICIPANT_ROTATIONAL_CAL') }}</strong>
                    <Button
                        v-if="isEditable && getRotationalCalendar"
                        :label="_t('label_unassign')"
                        class="p-button-danger"
                        style="float: right"
                        @click="onUnAssignRotationalCalendar"
                    />
                  </div>
                </div>
              </div>
              <RotationalCalendarConfigurator v-if="getRotationalCalendar"
                                              v-model:orgId="orgId">

              </RotationalCalendarConfigurator>
              <template v-else-if="isEditable">
                <div class="p-grid">
                  <div class="p-col-3 p-mt-2">
                    {{ _t('label_assign') }}
                  </div>
                  <div class="p-col p-mt-2">
                    <Dropdown v-model="selectedRotationalCalendar" :options="getRotationalCalendars"
                              :placeholder="_t('label_assign')"
                              optionLabel="name"
                              style="min-width: 400px; width: 100%"
                              :editable="true"
                    >
                    </Dropdown>
                  </div>
                  <div class="p-col-3 p-mt-2">
                    <Button
                        :disabled="!selectedRotationalCalendar && !selectedRotationalCalendar.id"
                        class="p-button-secondary"
                        @click="onAssignRotationalCalendar"
                    >
                      {{ _t('label_assign') }}
                    </Button>
                  </div>
                  <div class="p-col-12">
                    <hr>
                  </div>
                  <div class="p-col-3">
                    {{ _t('label_Create_configure') }}
                  </div>
                  <div class="p-col p-d-flex">
                    <InputText v-model="rotationalCalendar.name" :placeholder="_t('Name')" class="p-d-inline-flex"
                               style="width: 100%"
                               type="text"/>
                    <InputText v-model="rotationalCalendar.description" :placeholder="_t('label_description')"
                               class="p-d-inline-flex"
                               style="width: 100%" type="text"/>
                    <Calendar v-model="rotationalCalendar.validFrom " class="p-d-inline-flex" dateFormat="dd.mm.yy"
                              style="width: 100%"/>
                  </div>
                  <div class="p-col-3">
                    <Button :disabled="!rotationalCalendar" class="p-button-secondary"
                            @click="onCreateRotationalCalendar">{{ _t('label_create') }}
                    </Button>
                  </div>
                </div>
              </template>
              <template v-else>{{ _t('label_no_schedule_assigned') }}</template>

              <div class="p-grid p-pt-6">
                <div class="p-col-12 stripedBg p-py-2" style="vertical-align: middle;">
                  <div class="p-mt-2" style="float: left">
                    <i class="pi pi-calendar"></i>
                    <strong>{{ _t('label_private_media_schedule') }}</strong>
                  </div>
                  <Button v-if="isEditable" class="p-button-secondary p-button-rounded" icon="pi pi-plus"
                          style="float: right"
                          @click="newEvent"></Button>
                </div>
              </div>
              <ParticipantCalendarConfigurator
                  v-if="getParticipantCalendar"
                  v-model:orgId="orgId"
                  :participant="selectedParticipant ?? null"
                  @clickEvent="clickEvent"
              >
                <template #title>
                  <div></div>
                </template>
              </ParticipantCalendarConfigurator>
            </TabPanel>
            <TabPanel :header="_t('label_App_access')">
              <div class="p-grid">
                <div class="p-col-4 stripedBg">
                  <p>{{ _t('label_username') }}<sup>*</sup></p>
                </div>
                <div class="p-col-8 stripedBg">
                  <div v-if="isEditable" class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-user"></i>
                    </span>
                    <InputText v-model="selectedParticipant.login" v-no-autocomplete class="p-m-0" maxlength="100"
                               type="text"/>
                    <Button :disabled="!originalParticipant.login"
                            class="p-button-danger p-button-text p-button-plain p-button-lg"
                            @click="initDeleteMobileAccess()">
                      <i class="pi pi-trash"></i>
                    </Button>
                  </div>
                  <p v-else><strong>{{ selectedParticipant.login }}</strong></p>
                </div>
                <div class="p-col-4">
                  <p>{{ _t('label_user_group') }}</p>
                </div>
                <div class="p-col-8">
                  <div v-if="isEditable" class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-users"></i>
                    </span>
                    <Dropdown v-model="selectedParticipant.userGroupId"
                              :filter="true"
                              :options="userGroups"
                              :placeholder="_t('label_select_role')"
                              class="p-m-0"
                              optionLabel="name"
                              optionValue="id"
                              @change="findUserGroup(selectedParticipant.userGroupId)"
                    />
                    <Button class="p-button-danger p-button-text p-button-plain p-button-lg"
                            @click="selectedParticipant.userGroupId = 0;">
                      <i class="pi pi-trash"></i>
                    </Button>
                  </div>
                  <Button v-else :disabled="!selectedParticipant.userGroupId" class="p-button-link text"
                          @click="toUserGroup(selectedParticipant.userGroupId)">
                    <SelectedOption v-model="selectedParticipant.userGroupId" :options="userGroups" default="..."
                                    optionLabel="name" optionValue="id"/>
                  </Button>
                </div>
                <div v-if="isEditable" class="p-col-4 stripedBg">
                  <p>{{ _t('label_password') }}<sup>*</sup></p>
                </div>
                <div v-if="isEditable" class="p-col-8 stripedBg">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-lock"></i>
                    </span>
                    <InputText v-model="selectedParticipant.password" v-no-autocomplete class="p-m-0" maxlength="100"
                               type="password"/>
                  </div>
                </div>
                <div v-if="isEditable" class="p-col-4">
                  <p>{{ _t('label_pls_repeat_pass') }}</p>
                </div>
                <div v-if="isEditable" class="p-col-8">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-lock"></i>
                    </span>
                    <InputText v-model="selectedParticipant._confirmPassword" v-no-autocomplete class="p-m-0"
                               maxlength="100" type="password"/>
                  </div>
                </div>
              </div>
              <div v-if="originalUserGroupObject">
                <h3 class="subhead p-mt-5">
                  {{ originalUserGroupObject ? originalUserGroupObject.name : '' }} {{ _t('label_access_rights') }}
                </h3>
                <TabView>
                  <TabPanel :header="_t('permissions')">
                    <div class="p-grid">
                      <div v-for="globalPermission of globalPermissions" :key="globalPermission"
                           class="p-field-checkbox p-col-12 p-md-4">
                        <Checkbox :id="'gl_p_'+globalPermission"
                                  v-model="originalUserGroupObject.generalPermissions"
                                  :disabled="true"
                                  :value="globalPermission"
                                  name="globalPermission"/>
                        <label :for="'gl_p_'+globalPermission">{{ formatTitle(globalPermission) }}</label>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel :header="_t('admin_permissions')">
                    <div class="p-grid">
                      <div v-for="adminPermission of adminPermissions" :key="adminPermission"
                           class="p-field-checkbox p-col-12 p-md-4">
                        <Checkbox :id="'adm_p_'+adminPermission"
                                  v-model="originalUserGroupObject.adminPermissions"
                                  :disabled="true"
                                  :value="adminPermission"
                                  name="adminPermission"/>
                        <label :for="'adm_p_'+adminPermission">{{ formatTitle(adminPermission) }}</label>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel :header="_t('label_access_rights')">
                    <TreeTable
                        v-model:expanded-keys="expandedTreeTableKeys"
                        v-model:selectionKeys="selectedTreeTableValue"
                        :value="organizations"
                        autoLayout
                        class="p-treetable-sm"
                        stripedRows
                        @node-expand="appendNode($event)"
                    >
                      <Column :expander="true" field="name" header="Name"/>
                      <Column v-for="item in accessRights" :key="item" :field="item">
                        <template #header>
                          <Button v-tooltip.top="formatTitle(item)" :icon="icons[item.toLowerCase()]"
                                  class="p-button-link" style="color: black;">
                          </Button>
                        </template>
                        <template #body="slotProps">
                          <Button
                              v-tooltip.bottom="formatTitle(slotProps.node.data.rights[item])"
                              :disabled="true"
                              :icon="actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null'].icon"
                              :style="`color: ${actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null' ].color};`"
                              class="p-button-text"
                          />
                        </template>
                      </Column>
                    </TreeTable>
                  </TabPanel>
                </TabView>
              </div>
            </TabPanel>
            <TabPanel :header="_t('label_Desktop_access')">
              <div v-if="selectedParticipant.linkedUser && selectedParticipant.linkedUser.id" class="p-grid">
                <div class="p-col-12" style="vertical-align: middle;">
                  <h3 class="subhead">{{ _t('linked_on_device') }}</h3>
                </div>
                <div class="p-col-5 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_name') }}</p>
                </div>
                <div class="p-col-7 stripedBg" style="vertical-align: middle;">
                  <p><strong>{{ selectedParticipant.linkedUser.name ?? '...' }}</strong></p>
                </div>
                <div class="p-col-5" style="vertical-align: middle;">
                  <p>{{ _t('label_surname') }}</p>
                </div>
                <div class="p-col-7" style="vertical-align: middle;">
                  <p><strong>{{ selectedParticipant.linkedUser.surname ?? '...' }}</strong></p>
                </div>
                <div class="p-col-5 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_username') }}</p>
                </div>
                <div class="p-col-7 stripedBg"
                     style="vertical-align: middle;  display: flex; flex-direction: row; align-items:center;">
                  <Button class="p-button-link text" @click="toUser(selectedParticipant.linkedUser.id)">
                    {{ selectedParticipant.linkedUser.login }}
                  </Button>
                  <Button icon="pi pi-copy" v-tooltip.top="_t('label_copy')" @click="copyUserNameToClipboard"
                          style="margin-left: 40px; background-color: green; border-color: green;"/>
                </div>
                <div class="p-col-5" style="vertical-align: middle;">
                  <p>{{ _t('label_role') }}</p>
                </div>
                <div class="p-col-7" style="vertical-align: middle;">
                  <div v-if="isEditable" class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-users"></i>
                    </span>
                    <Dropdown v-model="linkedUserGroupId"
                              :filter="true"
                              :options="userGroups"
                              class="p-m-0"
                              optionLabel="name"
                              optionValue="id"
                              placeholder="Select a role"
                              @change="findLinkedUserGroup(selectedParticipant.linkedUser.userGroupId); selectedParticipant.linkedUser.isDirty = true;"
                    />
                    <Button class="p-button-danger p-button-text p-button-plain p-button-lg"
                            @click="linkedUserGroupId = null; selectedParticipant.linkedUser.userGroupId = 0; selectedParticipant.linkedUser.isDirty = true;">
                      <i class="pi pi-trash"></i>
                    </Button>
                  </div>
                  <Button v-else :disabled="!selectedParticipant.linkedUser.userGroupId"
                          class="p-button-link text"
                          @click="toUserGroup(selectedParticipant.linkedUser.userGroupId)">
                    <SelectedOption v-model="selectedParticipant.linkedUser.userGroupId" :options="userGroups"
                                    default="..." optionLabel="name" optionValue="id"/>
                  </Button>
                </div>
                <div class="p-col-5 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_personal_id') }}</p>
                </div>
                <div class="p-col-7 stripedBg" style="vertical-align: middle;">
                  <p><strong>{{
                      selectedParticipant.linkedUser.personalId === null || selectedParticipant.linkedUser.personalId === "" ? '...' : selectedParticipant.linkedUser.personalId
                    }}</strong></p>
                </div>
                <div class="p-col-5" style="vertical-align: middle;">
                  <p>{{ _t('label_organization') }}</p>
                </div>
                <div class="p-col-7" style="vertical-align: middle;">
                  <OrganizationLink v-model="selectedParticipant.linkedUser" :editMode="false"/>
                </div>
                <div class="p-col-5 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_2FA') }}</p>
                </div>
                <div class="p-col-7 stripedBg" style="vertical-align: middle; padding-top: 0; padding-bottom: 0;">
                  <Button :label="isSetResetToggle ? _t('label_Display_QR_code') : _t('label_reset_2fa_participant')"
                          class="p-button p-button-primary"
                          v-tooltip.top="isSetResetToggle ? _t('label_Display_QR_code') : _t('label_reset_2fa_participant')"
                          @click="handleClickSetReset"/>
                </div>
                <div v-if="isEditable" class="p-col-5 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('remove_desktop_access') }}</p>
                </div>
                <div v-if="isEditable" class="p-col-7 stripedBg" style="vertical-align: middle;">
                  <Button :label="_t('Delete')" class="p-button-danger p-button-plain p-button-lg" icon="pi pi-trash"
                          @click="deleteSystemAccessDialog = true"/>
                </div>
              </div>
              <div v-if="!selectedParticipant.linkedUser" class="p-grid">
                <div class="p-col-12">
                  <p>{{ _t('label_no_linked_user') ?? 'This participant has no linked user.' }} </p>
                </div>
                <div v-if="isEditable" class="p-col-5 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_add_desktop_access') }}</p>
                </div>
                <div v-if="isEditable" class="p-col-7 stripedBg" style="vertical-align: middle;">
                  <Button :label="_t('label_add_new')" class="p-button-secondary p-button-plain p-button-lg"
                          icon="pi pi-link"
                          @click="initAddSystemAccessDialog()"/>
                </div>

              </div>
              <div v-if="linkedUserGroupObject">
                <h3 class="subhead p-mt-5">
                  Effective {{ linkedUserGroupObject ? linkedUserGroupObject.name : '' }} role permissions
                </h3>
                <TabView>
                  <TabPanel :header="_t('permissions')">
                    <div class="p-grid">
                      <div v-for="globalPermission of globalPermissions" :key="globalPermission"
                           class="p-field-checkbox p-col-12 p-md-4">
                        <Checkbox :id="'gl_p_'+globalPermission"
                                  v-model="linkedUserGroupObject.generalPermissions"
                                  :disabled="true"
                                  :value="globalPermission"
                                  name="globalPermission"/>
                        <label :for="'gl_p_'+globalPermission">{{ formatTitle(globalPermission) }}</label>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel :header="_t('admin_permissions')">
                    <div class="p-grid">
                      <div v-for="adminPermission of adminPermissions" :key="adminPermission"
                           class="p-field-checkbox p-col-12 p-md-4">
                        <Checkbox :id="'adm_p_'+adminPermission"
                                  v-model="linkedUserGroupObject.adminPermissions"
                                  :disabled="true"
                                  :value="adminPermission"
                                  name="adminPermission"/>
                        <label :for="'adm_p_'+adminPermission">{{ formatTitle(adminPermission) }}</label>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel :header="_t('role_permissions')">
                    <TreeTable
                        v-model:expanded-keys="expandedTreeTableKeys"
                        v-model:selectionKeys="selectedTreeTableValue"
                        :value="organizations"
                        autoLayout
                        class="p-treetable-sm"
                        stripedRows
                        @node-expand="appendNode($event)"
                    >
                      <Column :expander="true" :header="_t('label_object_name')" field="name"/>
                      <Column v-for="item in accessRights" :key="item" :field="item">
                        <template #header>
                          <Button v-tooltip.top="formatTitle(item)" :icon="icons[item.toLowerCase()]"
                                  class="p-button-link" style="color: black;">
                          </Button>
                        </template>
                        <template #body="slotProps">
                          <Button
                              v-tooltip.bottom="formatTitle(slotProps.node.data.rights[item])"
                              :disabled="true"
                              :icon="actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null'].icon"
                              :style="`color: ${actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null' ].color};`"
                              class="p-button-text"
                          />
                        </template>
                      </Column>
                    </TreeTable>
                  </TabPanel>
                </TabView>
              </div>
            </TabPanel>
            <TabPanel :header="_t('label_groups')">
              <div class="p-grid">
                <p v-if="!selectedParticipantGroups.length">{{ _t('label_no_group_membership') }}</p>
                <div v-for="group in selectedParticipantGroups" :key="group.id" class="p-col-3">
                  <i class="pi pi-users p-mr-2"/>
                  <Button :label="group.name" class="p-button-link text" @click="toGroup(group.id)"/>
                </div>
              </div>
            </TabPanel>
            <TabPanel :header="_t('menu.participant_plans')">
              <div class="p-grid">
                <p v-if="!selectedParticipantAlarmPlans.length">{{ _t('label_no_plan') }}</p>
                <div v-for="plan in selectedParticipantAlarmPlans" :key="plan.id" class="p-col-3">
                  <i class="pi pi-exclamation-triangle p-mr-2"/>
                  <Button :label="plan.name" class="p-button-link text" @click="toAlarmPlan(plan.id)"/>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </template>
      </Card>
    </transition>
  </div>

  <Dialog v-model:visible="calendarDlg" style="width:70%; height:70%;">
    <div style="width:100%; height:70%;">
      <PrimeFullCalendar :events="events" :options="options"/>
      <Dialog v-model:visible="eventDialog" :closable="true" :header="_t('label_details')" :modal="true"
              :style="{width: '450px'}">
        <div class="p-fluid">
          <div class="p-field">
            <label for="title">{{ _t('label_title') }}</label>
            <InputText v-if="clickedEvent" id="title" v-model="changedEvent.title" autofocus maxlength="255"
                       required="true"/>
          </div>
          <div class="p-field">
            <label for="start">{{ _t('label_from') }}</label>
            <Calendar v-if="clickedEvent" id="start" v-model="changedEvent.start" :showTime="true" appendTo="body"/>
          </div>
          <div class="p-field">
            <label for="end">{{ _t('label_to') }}</label>
            <Calendar v-if="clickedEvent" id="end" v-model="changedEvent.end" :showTime="true" appendTo="body"/>
          </div>
          <div class="p-field-checkbox">
            <Checkbox id="allday" v-model="changedEvent.allDay" name="allday" value="All Day"/>
            <label for="allday">{{ _t('label_all_day') }}</label>
          </div>
        </div>
        <template #footer>
          <Button :label="_t('Save')" class="p-button-text" icon="pi pi-check" @click="save"/>
          <Button :label="_t('Reset')" class="p-button-text" icon="pi pi-refresh" @click="reset"/>
        </template>
      </Dialog>
    </div>
  </Dialog>

  <Dialog v-model:visible="addMobileAccessDialog" style="width: 30rem;">
    <template #header>
      <h3>{{ _t('label_add_new') }}</h3>
    </template>
    <div class="p-inputgroup p-mt-2">
      <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-user"></i>
      </span>
        <InputText v-model="selectedParticipant.login" v-no-autocomplete :placeholder="_t('label_username')"
                   maxlength="255"/>
      </div>
    </div>
    <div class="p-inputgroup p-mt-2">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-lock"></i>
        </span>
        <InputText v-model="selectedParticipant.password" v-no-autocomplete :placeholder="_t('label_password')"
                   maxlength="255"
                   type="password"/>
      </div>
    </div>
    <div class="p-inputgroup p-mt-2">
      <span class="p-inputgroup-addon">
        <i class="pi pi-lock"></i>
      </span>
      <InputText v-model="selectedParticipant._confirmPassword" v-no-autocomplete
                 :placeholder="_t('label_pls_repeat_pass')"
                 maxlength="255" type="password"/>
    </div>
    <div class="p-inputgroup p-mt-2">
      <span class="p-inputgroup-addon">
        <i class="pi pi-users"></i>
      </span>
      <Dropdown v-model="selectedParticipant.userGroupId"
                :filter="true"
                :options="userGroups"
                class="p-m-0"
                optionLabel="name"
                optionValue="id"
                placeholder="Select a role"
                @change="findUserGroup(selectedParticipant.userGroupId)"
      />
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="cancelAddMobileAccess()"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="validateMobileAccess()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="addSystemAccessDialog" style="width: 30rem;">
    <template #header>
      <h3>{{ _t('label_add_desktop_access') ?? 'Add desktop access' }}</h3>
    </template>
    <div v-if="addSystemAccessDialogForceNew">
      <p>
        Are you sure you want to give desktop access to this participant? If yes, please select access role below.
      </p>
      <div class="p-inputgroup p-mt-2">
        <span class="p-inputgroup-addon">
          <i class="pi pi-users"></i>
        </span>
        <Dropdown v-model="linkedUserGroupId"
                  :filter="true"
                  :options="userGroups"
                  class="p-m-0"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select a role"
        />
      </div>
    </div>
    <div v-else>
      <p>{{ _t('label_Desktop_access_subline') }}</p>
      <div class="p-inputgroup p-mt-2">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-user"></i>
          </span>
          <InputText v-model="selectedParticipant.login" v-no-autocomplete maxlength="255"
                     :placeholder="_t('label_username')"/>
        </div>
      </div>
      <div class="p-inputgroup p-mt-2">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-lock"></i>
          </span>
          <InputText v-model="selectedParticipant.password" v-no-autocomplete maxlength="255"
                     :placeholder="_t('label_password')"
                     type="password"/>
        </div>
      </div>
      <div class="p-inputgroup p-mt-2">
        <span class="p-inputgroup-addon">
          <i class="pi pi-lock"></i>
        </span>
        <InputText v-model="selectedParticipant._confirmPassword" v-no-autocomplete
                   :placeholder="_t('label_password_repeat')"
                   maxlength="255" type="password"/>
      </div>
      <div class="p-inputgroup p-mt-2">
        <span class="p-inputgroup-addon">
          <i class="pi pi-users"></i>
        </span>
        <Dropdown v-model="linkedUserGroupId"
                  :filter="true"
                  :options="userGroups"
                  class="p-m-0"
                  optionLabel="name"
                  optionValue="id"
                  :placeholder="_t('label_select_role')"
        />
      </div>
    </div>
    <p class="p-error p-pt-1" v-show="!linkedUserGroupId">{{ _t('label_role_should_be_assigned') }}</p>
    <p class="p-error p-pt-1" v-show="!this.isValidAccessToDesktopClient">{{
        _t('label_fill_in_to_activate_button')
      }}</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="cancelAddSystemAccess()"/>
      <Button :label="_t('Save')"
              autofocus
              class="p-button-success"
              :disabled="!this.isValidAccessToDesktopClient"
              @click="validateSystemAccess()"
      />
    </template>
  </Dialog>

  <Dialog v-model:visible="newParticipantDialog" :style="{ width: '450px' }">
    <template #header>
    </template>
    <h3>{{ _t('label_create_new_participant') }}</h3>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-6 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-pencil"></i>
          </span>
          <InputText v-model="newParticipant.name" v-no-autocomplete :placeholder="_t('label_name') + '*'"
                     maxlength="255"/>
        </div>
      </div>
      <div class="p-col-6 p-field">
        <InputText v-model="newParticipant.surname" v-no-autocomplete :placeholder="_t('label_surname') + '*'"
                   maxlength="255"/>
      </div>
    </div>

    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-6 p-field">
        <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-pencil"></i>
        </span>
          <Dropdown
              v-model="newParticipant.language"
              :options="languages"
              :placeholder="_t('label_language')"
              optionLabel="label"
              optionValue="value"/>
        </div>
      </div>
      <div class="p-col-6 p-field">
        <InputText v-model="newParticipant.personalNumber" v-no-autocomplete :placeholder="_t('label_personal_id')"
                   maxlength="255"
                   type="text"/>
      </div>
    </div>

    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <OrganizationLink v-model="newParticipant" :editMode="true" :required-field="true"
                            @change="getParticipantGroups(newParticipant.orgId)"/>
        </div>
      </div>
    </div>

    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-6 p-field">
        <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-users"></i>
        </span>
          <Dropdown
              v-model="newParticipant.participantGroupId" :filter="true"
              :options="participantGroups" :placeholder="_t('label_pg')" class="p-ml-0" optionLabel="name"
              optionValue="value"
              @change="selectParticipantGroup"
          />
        </div>
      </div>
      <div class="p-col-6 p-field">
        <Dropdown
            v-model="newParticipant.participantTargetGroupId" :disabled="!targetGroups.length" :filter="true"
            :options="targetGroups" :placeholder="_t('label_Select_target_group')" class="p-ml-0" optionLabel="name"
            optionValue="value"/>
      </div>

    </div>

    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="newParticipantDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createParticipant()"/>
    </template>
  </Dialog>
  <!--  <Dialog v-model:visible="newMediaDialog">-->
  <!--    <template #header>-->
  <!--    </template>-->
  <!--    <h3>Add new media</h3>-->
  <!--    <div class="p-inputgroup">-->
  <!--      <span class="p-inputgroup-addon">-->
  <!--        <label>Active</label>-->
  <!--      </span>-->
  <!--      <Checkbox v-model="newMedia.active" :binary="true"/>-->
  <!--    </div>-->

  <!--    <div class="p-inputgroup">-->
  <!--      <span class="p-inputgroup-addon">-->
  <!--        <label>Device</label>-->
  <!--      </span>-->
  <!--      <Dropdown :options="mediaDeviceTypes" optionLabel="message" v-model="newMedia.deviceObject"/>-->
  <!--    </div>-->

  <!--    <div class="p-inputgroup">-->
  <!--      <span class="p-inputgroup-addon">-->
  <!--        <label>Acknowledge Time</label>-->
  <!--      </span>-->
  <!--      <InputText maxlength="255" v-model="newMedia.acknowledgeTime" type="text"/>-->
  <!--    </div>-->

  <!--    <div class="p-inputgroup">-->
  <!--      <span class="p-inputgroup-addon">-->
  <!--        <label>Calling Number/Username</label>-->
  <!--      </span>-->
  <!--      <InputText maxlength="255" v-model="newMedia.callingNumber" type="text"/>-->
  <!--    </div>-->

  <!--    <div class="p-inputgroup">-->
  <!--      <span class="p-inputgroup-addon">-->
  <!--        <label>Rank</label>-->
  <!--      </span>-->
  <!--      <InputNumber v-model="newMedia.rank"  showButtons/>-->
  <!--    </div>-->

  <!--    <template #footer>-->
  <!--      <Button class="p-button-outlined p-button-secondary" :label="_t('Cancel')" @click="cancelnewMedia"/>-->
  <!--      <Button autofocus class="p-button-success" :label="_t('Save')" @click="saveNewMedia"/>-->
  <!--    </template>-->
  <!--  </Dialog>-->

  <Dialog v-model:visible="deleteParticipantDialog" style="width: 30rem;">
    <template #header>
      <h3>{{ _t('label_delete_participant') }}</h3>
    </template>
    <p>
      <strong>
        {{ selectedParticipant.name }} {{ selectedParticipant.surname }}:
      </strong>
      <br>
      {{ _t('label_delete_record') }}
    </p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteParticipantDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteParticipant"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteMobileAccessDialog" style="width: 30rem;">
    <template #header>
      <h3>{{ _t('label_Remove_access_role') }}</h3>
    </template>

    <p>Are you sure you want to remove mobile access from this participant (login name
      <strong>{{ selectedParticipant.login }}</strong>)?
      This will cause that this participant will not be able to login in the mobile app, and won't be able to receive or
      send alarms via the mobile app.</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteMobileAccessDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteMobileAccess()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteSystemAccessDialog" style="width: 30rem;">
    <template #header>
      <h3>{{ _t('label_delete_desktop_access') ?? 'Delete desktop access' }}</h3>
    </template>

    <p>Are you sure you want to delete desktop access of this participant and his username
      <strong>{{ selectedParticipant.login }}</strong>?</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteSystemAccessDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteSystemAccess()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="newEventDialog" :modal="true" :style="{width: '660px'}">
    <template #header>
      <h3>{{ _t('indCalEdit') }}</h3>
    </template>

    <span class="p-float-label p-mt-5">
      <InputText id="description" v-model="getEvent.title" style="width: 100%" type="text"/>
      <label for="description">{{ _t('label_description') }}</label>
    </span>

    <span class="p-field p-mt-5">
      <label for="device"
             style="color: #6c757d; margin-top: 10px; margin-bottom: 1px; margin-left: 5px;">{{
          _t('DEVICE_GROUP')
        }}</label>
      <Dropdown
          id="device"
          v-model="getEvent.deviceType"
          :options="getDevices"
          :placeholder="_t('_NONE')"
          :showClear="true"
          class="p-p-0"
          dataKey="message"
          optionLabel="message"
          style="width: 100%"
      />
    </span>

    <span class="p-float-label p-mt-5">
      <InputText id="callingNumber" v-model="getEvent.callingNumber" style="width: 100%" type="text"/>
      <label for="callingNumber">{{ _t('label_call_number') }}</label>
    </span>

    <span class="p-float-label p-mt-5">
      <InputNumber id="acknowledgeTime" v-model="getEvent.acknowledgeTime" :max="99999999999" :min="1"
                   :useGrouping="false"
                   class="p-p-0" mode="decimal" style="width: 100%" type="text"/>
      <label for="acknowledgeTime">{{ _t('label_ack_time') }}</label>
    </span>

    <div class="p-grid p-mt-5">
      <div class="p-col-4">
        <span class="p-float-label">
          <Calendar id="startDateSelected" v-model="getEvent.startDateSelected" :showTime="false"
                    dateFormat="dd.mm.yy"/>
          <label for="startDateSelected">{{ _t('label.Start_date') }}</label>
        </span>
      </div>
      <div class="p-col-3">
        <span class="p-float-label">
          <Calendar id="startTime" v-model="getEvent.startTime" :disabled="allDay" :showTime="true" :timeOnly="true"/>
          <label for="startTime">{{ _t('label.Start_time') }}</label>
        </span>
      </div>
      <div class="p-col-3">
        <span class="p-float-label">
          <Calendar id="endTime" v-model="getEvent.endTime" :disabled="allDay" :showTime="true" :timeOnly="true"/>
          <label for="endTime">{{ _t('label.End_time') }}</label>
        </span>
      </div>
      <div class="p-col-2 p-pt-2">
        <div class="p-field-checkbox p-pt-1">
          <Checkbox id="allday" v-model="allDay" :binary="true"/>
          <label for="allday">{{ _t('label_all_day') }}</label>
        </div>
      </div>
    </div>

    <span class="p-float-label p-mt-3">
      <Checkbox id="active" v-model="getEvent.active" :binary="true"/>
      <label class="p-ml-4" for="active">{{ _t('label_active_alarm') }}</label>
    </span>

    <span class="p-float-label p-mt-3">
      <Checkbox id="repeatType" v-model="getEvent.recurring" :binary="true"/>
      <label class="p-ml-4" for="repeatType">{{ _t('repeat_type') }}</label>
    </span>

    <div v-if="getEvent.recurring">
      <span class="p-float-label p-mt-3">
        <Dropdown
            v-model="getEvent.repeatType"
            :options="getRepeatTypes"
            class="p-p-0"
            optionValue="value"
            placeholder="Select a type"
            style="width: 100%"
        >
          <template #option="slotProps">
            {{ _t(slotProps.option.value) }}
          </template>
          <template #value="slotProps">
            {{ _t(slotProps.value) }}
          </template>
        </Dropdown>
      </span>

      <span class="p-float-label p-mt-5">
        <InputNumber id="repeatEvery" v-model="getEvent.repeatEvery" :useGrouping="false" class="p-p-0" mode="decimal"
                     style="width: 100%" type="text"/>
        <label for="repeatEvery">{{ _t('label_repeat_every') }} ({{ repeatEveryTitle }})</label>
      </span>
      <span v-if="getEvent.repeatType === repeatTypeEnum.WEEKLY" class="p-d-flex p-mt-4">
        <label class="p-mt-1 p-mr-4">{{ _t('label_repeat_by') }}</label>
        <div v-for="weekDay in weekDayEnum" :key="weekDay" class="p-d-inline">
          <Checkbox :id="weekDay" v-model="getEvent.markedDays" :name="weekDay" :value="weekDay"/>
          <label :for="weekDay" class="p-pl-1 p-pr-3">{{ weekDay.charAt(0).toUpperCase() }}</label>
        </div>
      </span>
      <span v-if="getEvent.repeatType === repeatTypeEnum.MONTHLY" class="p-d-flex p-mt-4">
        <label class="p-mt-1 p-mr-4">{{ _t('label_repeat_by') }}</label>
        <Dropdown
            v-model="getEvent.monthSubType"
            :options="repeatByMonth"
            class="p-p-0"
            optionLabel="label"
            optionValue="value"
            placeholder="Select a type"
            style="width: 100%"
        />
      </span>
      <div class="p-d-flex p-flex-row p-mt-5">
        <div class="p-d-inline-flex p-ai-start p-mt-4 p-pr-4"><label class="p-ml-2"
                                                                     for="ends">{{ _t('label_ends') }}</label></div>
        <div class="p-d-inline-flex p-flex-column p-ai-start p-jc-start p-pr-4">
          <div class="p-d-inline-flex">
            <span class="p-field-radiobutton">
              <RadioButton id="endsAfter" v-model="getEvent.endType" value="AFTER_TIMES_END"/>
              <label for="endsAfter">{{ _t('label_after') }}</label>
            </span>
          </div>
          <div class="p-d-inline-flex">
            <span class="p-field-radiobutton">
              <RadioButton id="endsOn" v-model="getEvent.endType" value="ON_DATE"/>
              <label for="endsOn">{{ _t('label.End_date') }}</label>
            </span>
          </div>
        </div>
        <div class="p-d-inline-flex p-flex-column p-ai-start p-jc-start p-pr-4" style="width: 100%">
          <span class="p-d-inline-flex p-pb-2" style="margin-top: -5px">
            <InputNumber id="countAfterTimesEnd" v-model="getEvent.countAfterTimesEnd"
                         :disabled="getEvent.endType !== 'AFTER_TIMES_END'" :useGrouping="false"
                         class="p-p-0" mode="decimal" style="width: 100%"
                         type="text"/>
            <label class="p-pt-2 p-pl-2" for="countAfterTimesEnd">{{ _t('label_times') }}</label>
          </span>
          <span class="p-d-inline-flex">
            <Calendar id="endDateSelected" v-model="getEvent.endDateSelected" :disabled="getEvent.endType !== 'ON_DATE'"
                      :showTime="false"
                      dateFormat="dd.mm.yy"/>
          </span>
        </div>
      </div>
    </div>

    <template #footer>
      <Button v-if="getIsEditable" :label="_t('Delete')" class="p-button-text p-button-danger" icon="pi pi-trash"
              style="float: left" @click="removeSelectedEvent()"/>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times"
              @click="setEvent(null); newEventDialog = false"/>
      <Button :label="_t('Save')" autofocus icon="pi pi-check" @click="createNewEvent()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="filtersDialog" style="max-width: 40em;">
    <template #header>
      <h3>{{ _t('label_advanced_filters') }}</h3>
    </template>
    <div class="p-grid">
      <div class="p-col-3">
        <p>{{ _t('label_surname') }}</p>
      </div>
      <div class="p-col-9">
        <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.surname = ''"/>
        <InputText v-model="listFilters.surname" style="width: 27rem;"/>
        </span>
      </div>
      <div class="p-col-3">
        <p>{{ _t('label_name') }}</p>
      </div>
      <div class="p-col-9">
        <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.name = ''"/>
        <InputText v-model="listFilters.name" style="width: 27rem;"/>
        </span>
      </div>
      <div class="p-col-3">
        <p>{{ _t('label_short_name') }}</p>
      </div>
      <div class="p-col-9">
        <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.shortName = ''"/>
        <InputText v-model="listFilters.shortName" style="width: 27rem;"/>
        </span>
      </div>
      <div class="p-col-3">
        <p>{{ _t('label_description') }}</p>
      </div>
      <div class="p-col-9">
        <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.description = ''"/>
        <InputText v-model="listFilters.description" style="width: 27rem;"/>
        </span>
      </div>
      <div class="p-col-3">
        <p>{{ _t('label_login') }}</p>
      </div>
      <div class="p-col-9">
        <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.login = ''"/>
        <InputText v-model="listFilters.login" style="width: 27rem;"/>
        </span>
      </div>
      <!--TODO: uncomment in 7.0.2-->
      <!--      <div class="p-col-3">-->
      <!--        <p>{{ _t('label_System_ID_linked') }}</p>-->
      <!--      </div>-->
      <!--      <div class="p-col-9">-->
      <!--      <span class="p-input-icon-right">-->
      <!--            <i class="pi pi-times" @click="listFilters.systemIdLinked = ''"/>-->
      <!--            <InputText v-model="listFilters.systemIdLinked"-->
      <!--                       style="width: 27rem;"/>-->
      <!--        </span>-->
      <!--      </div>-->

      <!--      <div class="p-col-3">-->
      <!--        <p>{{ _t('label_System_ID_excluded') }}</p>-->
      <!--      </div>-->
      <!--      <div class="p-col-9">-->
      <!--      <span class="p-input-icon-right">-->
      <!--            <i class="pi pi-times" @click="listFilters.systemIdExclude = ''"/>-->
      <!--            <InputText v-model="listFilters.systemIdExclude"-->
      <!--                       style="width: 27rem;"/>-->
      <!--        </span>-->
      <!--      </div>-->

      <!--      <div class="p-col-3">-->
      <!--        <p>{{ _t('label_Alarm_ID_linked') }}</p>-->
      <!--      </div>-->
      <!--      <div class="p-col-9">-->
      <!--      <span class="p-input-icon-right">-->
      <!--            <i class="pi pi-times" @click="listFilters.alarmIdLinked = ''"/>-->
      <!--            <InputText v-model="listFilters.alarmIdLinked"-->
      <!--                       style="width: 27rem;"/>-->
      <!--        </span>-->
      <!--      </div>-->

      <!--      <div class="p-col-3">-->
      <!--        <p>{{ _t('label_Alarm_ID_excluded') }}</p>-->
      <!--      </div>-->
      <!--      <div class="p-col-9">-->
      <!--      <span class="p-input-icon-right">-->
      <!--            <i class="pi pi-times" @click="listFilters.alarmIdExclude = ''"/>-->
      <!--            <InputText v-model="listFilters.alarmIdExclude"-->
      <!--                       style="width: 27rem;"/>-->
      <!--        </span>-->
      <!--      </div>-->

      <!--      <div class="p-col-3">-->
      <!--        <p>{{ _t('label_Alarm_name_linked') }}</p>-->
      <!--      </div>-->
      <!--      <div class="p-col-9">-->
      <!--      <span class="p-input-icon-right">-->
      <!--            <i class="pi pi-times" @click="listFilters.alarmNameLinked = ''"/>-->
      <!--            <InputText v-model="listFilters.alarmNameLinked"-->
      <!--                       style="width: 27rem;"/>-->
      <!--        </span>-->
      <!--      </div>-->

      <!--      <div class="p-col-3">-->
      <!--        <p>{{ _t('label_Alarm_name_excluded') }}</p>-->
      <!--      </div>-->
      <!--      <div class="p-col-9">-->
      <!--      <span class="p-input-icon-right">-->
      <!--            <i class="pi pi-times" @click="listFilters.alarmNameExclude = ''"/>-->
      <!--            <InputText v-model="listFilters.alarmNameExclude"-->
      <!--                       style="width: 27rem;"/>-->
      <!--        </span>-->
      <!--      </div>-->

      <!--      <div class="p-col-3">-->
      <!--        <p>{{ _t('label_Alarm_plan_description_excluded') }}</p>-->
      <!--      </div>-->
      <!--      <div class="p-col-9">-->
      <!--      <span class="p-input-icon-right">-->
      <!--            <i class="pi pi-times" @click="listFilters.alarmPlanDescriptionExclude = ''"/>-->
      <!--            <InputText v-model="listFilters.alarmPlanDescriptionExclude"-->
      <!--                       style="width: 27rem;"/>-->
      <!--        </span>-->
      <!--      </div>-->

      <!--      <div class="p-col-3">-->
      <!--        <p>{{ _t('label_Alarm_plan_description_linked') }}</p>-->
      <!--      </div>-->
      <!--      <div class="p-col-9">-->
      <!--      <span class="p-input-icon-right">-->
      <!--            <i class="pi pi-times" @click="listFilters.alarmPlanDescriptionLinked = ''"/>-->
      <!--            <InputText v-model="listFilters.alarmPlanDescriptionLinked"-->
      <!--                       style="width: 27rem;"/>-->
      <!--        </span>-->
      <!--      </div>-->

      <div class="p-col-3">
        <p>{{ _t('label_Schedules.filterPart') }}</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <Dropdown v-model="listFilters.hasSchedule"
                      show-clear
                      :options="['ANY', 'YES', 'NO']"
                      class="p-m-0"
                      style="width: 27rem;"/>
        </span>
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Acknowledgments_code') }}</p>
      </div>
      <div class="p-col-9">
            <span class="p-input-icon-right">
                  <Dropdown v-model="listFilters.ackCode"
                            show-clear
                            :options="['ANY', 'YES', 'NO']"
                            class="p-m-0"
                            style="width: 27rem;"/>
              </span>
      </div>
      <!--TODO: uncomment in 7.0.2-->
      <!--      <div class="p-col-3">-->
      <!--        <p>{{ _t('label_Positive_acknowledgments') }}</p>-->
      <!--      </div>-->
      <!--      <div class="p-col-9">-->
      <!--      <span class="p-input-icon-right">-->
      <!--            <i class="pi pi-times" @click="listFilters.positiveAcknowledges = ''"/>-->
      <!--            <InputText v-model="listFilters.positiveAcknowledges"-->
      <!--                       style="width: 27rem;"/>-->
      <!--        </span>-->
      <!--      </div>-->

      <div class="p-col-3">
        <p>{{ _t('label_personal_num') }}</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.personalNumber = ''"/>
            <InputText v-model="listFilters.personalNumber"
                       style="width: 27rem;"/>
        </span>
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Function') }}</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.function = ''"/>
            <InputText v-model="listFilters.function"
                       style="width: 27rem;"/>
        </span>
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Excluded_alarms_by_type') }}</p>
      </div>
      <div class="p-col-9">
        <Dropdown
            show-clear
            v-model="listFilters.excdlueAlarmsByType"
            :options="['NONE', 'CONSEQUENTIAL', 'PREDEFINED', 'MANUAL_CALL', 'LONEWORKER', 'TEMPLATE', 'TECHNICAL', 'BASIC'] "
            class="p-m-0"
            style="width:27rem;"
        />
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Assigned_to_a_group') }}</p>
      </div>
      <div class="p-col-9">
        <Dropdown
            show-clear
            v-model="listFilters.assignedToGroup"
            :options="['ANY', 'YES', 'NO']"
            class="p-m-0"
            style="width:27rem;"
        />
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Assigned_to_a_plan') }}</p>
      </div>
      <div class="p-col-9">
        <Dropdown
            show-clear
            v-model="listFilters.assignedToAlarmPlan"
            :options="['ANY', 'YES', 'NO']"
            class="p-m-0"
            style="width:27rem;"
        />
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Has_desktop_access') }}</p>
      </div>
      <div class="p-col-9">
        <Dropdown
            show-clear
            v-model="listFilters.hasDesktopAccess"
            :options="['ANY', 'YES', 'NO']"
            class="p-m-0"
            style="width:27rem;"
        />
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Has_app_access') }}</p>
      </div>
      <div class="p-col-9">
        <Dropdown
            show-clear
            v-model="listFilters.hasAppAccess"
            :options="['ANY', 'YES', 'NO']"
            class="p-m-0"
            style="width:27rem;"
        />
      </div>
    </div>
    <template #footer>
      <Button :label="_t('Clear')" class="p-button-text p-button-danger" icon="pi pi-times"
              @click="resetListFilters"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" icon="pi pi-check" @click="applyListFilters()"/>
    </template>
  </Dialog>
  <Dialog style="width: 30vw;"
          :header="!temp2faAuth.qrCodePath ? _t('label_password_confirm') : _t('label_scan_qr_code')" modal
          v-model:visible="isSet2faModal">
    <div v-if="!temp2faAuth.qrCodePath"
         style="width: 50%; margin: 0 auto; display: flex; flex-direction: column; align-items: center; justify-content: center;">
      <InputText :placeholder="_t('label_password')" type="password" style="width: 100%; margin-bottom: 20px;"
                 v-model="temp2faAuth.setPasswordData"/>
      <Button style="width: 100%;" :label="_t('label_Display_QR_code')" @click="set2faParticipant"
              :disabled="!temp2faAuth.setPasswordData"/>
    </div>
    <div v-else>
      <div style="width: 100%; display: flex; justify-content: center;">
        <img :src="temp2faAuth.qrCodePath" alt="qr_code"/>
      </div>
      <br>
      <InputText :placeholder="_t('label_enter_otp')" style="width: 100%; margin-bottom: 20px;"
                 v-model="temp2faAuth.code"/>
      <br>
      <Button style="width: 100%;" :label="_t('label_Display_QR_code')" @click="auth2faParticipant"/>
    </div>
  </Dialog>
  <Toast />
</template>

<script>
import {getList, getListExtended} from '@/api/list'
import {getTree} from "@/api/tree";
import {findUserGroup, getAccessRights, getAdminPermissions, getPermissions, getUGTree} from '@/api/userGroup'
import {
  cloneParticipant,
  createParticipant,
  deleteParticipant,
  deviceList,
  getParticipant,
  getParticipantAlarmPlans,
  getParticipantByLogin,
  getParticipantGroups,
  mergeParticipantToUser,
  updateParticipant
} from "@/api/participant";
import {deleteUser, getUser, getUserByLogin, updateUser} from "@/api/user";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import MultiSelectableBreadcrumb from "@/components/ixarma/MultiSelectableBreadcrumb";
import OrganizationLink from "../../components/ixarma/OrganizationLink";
import {getParticipantGroup, updateParticipantGroup} from "@/api/participantGroup";
import router from '@/router'
import SelectedOption from "../../components/ixarma/SelectedOption";
import RotationalCalendarConfigurator from '@/views/team_media_schedules/calendar-configurator';
import ParticipantCalendarConfigurator from './calendar-configurator';
import {mapActions, mapGetters, mapMutations} from "vuex";
import {RepeatTypeEnum} from "@/enums/repeat-type.enum";
import {WeekDayEnum} from "@/enums/week-day.enum";
import moment from "moment-timezone";
import {getOrganizationColumns, getUserColumns} from "@/api/uiColumns";
import {generateQrUrl, get2faStatus, login, reset2faStatus} from "@/api/auth";

export default {
  name: 'index',
  components: {
    SelectedOption,
    OrganizationLink,
    MultiSelectableBreadcrumb,
    RotationalCalendarConfigurator,
    ParticipantCalendarConfigurator
  },
  data() {
    return {
      newMediaDialog: false,
      newMedia: {},
      isEditable: false,
      changePassDlg: false,
      addCusDlg: false,
      savedMedia: {},
      participantFilterValue: '',
      participants: null,
      totalRecords: 100,
      currentPage: 0,
      loading: true,
      dataTabs: false,
      statuses: [
        'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
      ],
      selectedTreeTableValue: null,
      expandedTreeTableKeys: {},
      participantGroups: [],
      targetGroups: [],
      newParticipant: {
        name: '',
        surname: '',
        login: '',
        password: null,
        language: null,
        orgName: '...',
        orgId: null,
        personalNumber: null,
        type: 'CUSTOM',
        version: 0,
        participantGroupId: null,
        participantTargetGroupId: null
      },
      selectedParticipantGroup: null,
      newParticipantDialog: false,
      deleteParticipantDialog: false,
      deleteSystemAccessDialog: false,
      addMobileAccessDialog: false,
      deleteMobileAccessDialog: false,
      originalParticipant: null,
      selectedParticipant: {
        id: null,
        name: '',
        surname: '',
        login: '',
        personalNumber: '',
        language: '',
        userGroupId: null,
        userGroup: {
          name: ''
        }
      },
      isValidAccessToDesktopClient: true,
      userGroups: [],
      linkedUserGroupId: null,
      calendarDlg: false,
      eventDialog: false,
      clickedEvent: null,
      languages: [],
      changedEvent: {title: '', start: null, end: '', allDay: null},
      options: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        defaultDate: '2022-01-01',
        header: {
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek'
        },
        editable: true,
        eventClick: (e) => {
          this.eventDialog = true;

          this.clickedEvent = e.event;

          this.changedEvent.title = this.clickedEvent.title;
          this.changedEvent.start = this.clickedEvent.start;
          this.changedEvent.end = this.clickedEvent.end;
        }
      },
      events: null,
      participantTableRows: 100,//localStorage.getItem('part  icipantTableRows') ?? '10',
      selectedParticipantItem: null,
      listFiltersEnabled: false,
      filtersDialog: false,
      listFilters: {},
      tableDataKey: 'id',
      pageSize: ['5', '10', '20', '50'],
      treeParams: '',
      globalPermissions: [],
      adminPermissions: [],
      accessRights: [],
      originalOrganisationsObject: [],
      originalUserGroupObject: null,
      linkedUserGroupObject: null,
      organizations: [],
      icons: {
        user: 'pi pi-fw pi-user',
        user_group: 'pi pi-fw pi-users',
        participant: 'pi pi-fw pi-user-minus',
        participant_group: 'pi pi-fw pi-users',
        participant_rotational_cal: 'pi pi-fw pi-tablet',
        participant_individual_cal: 'pi pi-fw pi-mobile',
        alarm_point: 'pi pi-fw pi-bell',
        alarm_point_rotational_cal: 'pi pi-fw pi-calendar-times',
        alarm_point_individual_cal: 'pi pi-fw pi-calendar-plus',
        alarm_plan: 'pi pi-fw pi-map',
        organization: 'pi pi-fw pi-globe',
        holiday: 'pi pi-fw pi-calendar-minus',
        device: 'pi pi-fw pi-desktop'
      },
      actions: {
        read: {
          icon: 'pi pi-fw pi-eye',
          color: 'blue'
        },
        write: {
          icon: 'pi pi-fw pi-pencil',
          color: 'green'
        },
        none: {
          icon: 'pi pi-fw pi-times',
          color: 'red'
        },
        null: {
          icon: '',
          color: 'grey'
        }
      },
      orgId: localStorage.getItem('orgId') ?? 1,
      selectedParticipantAlarmPlans: [],
      selectedParticipantGroups: [],
      mediaDeviceTypes: [],
      addSystemAccessDialog: false,
      addSystemAccessDialogForceNew: false,
      selectedRotationalCalendar: '',
      rotationalCalendar: {
        name: '',
        description: '',
        validFrom: ''
      },
      newEventDialog: false,
      repeatByMonth: [{
        label: 'Day of month',
        value: 'DAY_OF_THE_MONTH',
      }, {
        label: 'Day of week',
        value: 'DAY_OF_THE_WEEK',
      }],
      allDay: false,
      activeTab: 0,
      childFilter: true,
      expandSidebarDialog: false,
      alarmParticipantsValues: [],
      isSet2faModal: false,
      isSetResetToggle: false,
      temp2faAuth: {
        setPasswordData: '',
        userLogin: '',
        qrCodePath: '',
        code: ''
      },
      calledWarning: false,
    }
  },

  methods: {
    ...mapActions({
      /* Rotational calendar */
      'callRotationalCalendarById': 'participantCalendar/callCalendarById',
      'callRotationalCalendars': 'participantCalendar/callCalendars',
      'createRotationalCalendar': 'participantCalendar/callCreateCalendar',
      'updateRotationalCalendar': 'participantCalendar/callUpdateCalendar',
      /* Individual calendar */
      'callParticipantCalendar': 'participant/callCalendar',
      'callCreateUpdateEvent': 'participant/callCreateUpdateEvent',
      'callRemoveEvent': 'participant/callRemoveEvent',
    }),
    ...mapMutations({
      /* Rotational calendar */
      'setRotationalIsEditable': 'participantCalendar/setIsEditable',
      'createEmptyCalendar': 'participantCalendar/createEmptyCalendar',
      'selectRotationalCalendar': 'participantCalendar/selectCalendar',
      /* Individual calendar */
      'setParticipantIsEditable': 'participant/setIsEditable',
      'createEmptyEvent': 'participant/createEmptyEvent',
      'setEvent': 'participant/setEvent',
      'selectEvent': 'participant/selectEvent',
    }),
    reset2faParticipant() {
      reset2faStatus(this.selectedParticipant.login).then((res) => {
        if (res.status === 200) {
          this.$toast.add({
            severity: 'success',
            summary: 'Successful',
            detail: this._t('label_reset_2fa_participant_success'),
            life: 3000
          })
        }
      })
    },
    handleClickSetReset() {
      this.temp2faAuth.qrCodePath = ''
      this.temp2faAuth.setPasswordData = ''
      if (this.isSetResetToggle) {
        this.isSet2faModal = true
      } else {
        this.reset2faParticipant()
      }
    },
    set2faParticipant() {
      this.temp2faAuth.userLogin = this.selectedParticipant.login
      generateQrUrl({username: this.temp2faAuth.userLogin, password: this.temp2faAuth.setPasswordData})
          .then((response) => {
            this.temp2faAuth.qrCodePath = response.data
          })
          .catch(error => {
            this.$toast.add({
              severity: 'error', summary: 'Successful',
              detail: this._t(error.response.data.reason), life: 3000
            })
          })
    },
    auth2faParticipant() {
      login({
        username: this.temp2faAuth.userLogin,
        password: this.temp2faAuth.setPasswordData,
        verificationCode: this.temp2faAuth.code
      })
      this.isSet2faModal = false
      this.selectParticipant(this.selectedParticipant.id)
      this.$toast.add({
        severity: 'success', summary: 'Successful',
        detail: '',
        life: 3000
      })
    },

    async copyUserNameToClipboard() {
      const el = document.createElement('textarea');
      el.value = this.selectedParticipant.linkedUser.login;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$toast.add({severity: 'success', summary: 'Successful', detail: this._t('label_copy_success'), life: 3000});
    },
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
          this.getParticipants()
          this.callRotationalCalendars({'tableRows': 100});
        }
      }, 100);
    },
    toggleSearch(event) {
      this.$refs.op.toggle(event);
    },
    clearFilter() {
      this.participantFilterValue = ''
    },
    onFilter() {
      this.getParticipants()
    },
    selectTablePage(item) {
      this.loading = true
      this.currentPage = item.page
      this.getParticipants();
    },
    selectParticipant(id, toEditMode = false) {
      this.$router.push({
        params: {
          id: id
        },
        query: {
          active_tab: this.activeTab ?? 0
        }
      });
      this.rotationalCalendar = {
        name: '',
        description: '',
        validFrom: ''
      };
      this.selectRotationalCalendar(null);
      getParticipant(id).then((response) => {
        this.processSetSelectedParticipant(response.data)
        this.dataTabs = true
        this.expandedTreeTableKeys = {};
        if (this.selectedParticipant.rotationalCalendarId) {
          this.callRotationalCalendarById(this.selectedParticipant.rotationalCalendarId)
            .then(() => {
              if(this.getRotationalCalendar && !this.calledWarning) {
                this.$toast.add({
                  severity: 'info',
                  summary: 'Schedule is defined',
                  detail: 'Note that a schedule of this alarm updates fully or partly participants defined on this tab',
                  life: 5000,
                  closable: true
                });
                this.calledWarning = true
              }
          });
        }
        if (this.selectedParticipant.id) {
          this.callParticipantCalendar(this.selectedParticipant.id);
        }
        this.rotationalCalendar = {
          name: '',
          description: '',
          validFrom: ''
        };
        if (toEditMode) {
          this.isEditable = true
        }
      })
      getParticipantGroups(id).then((response) => {
        this.selectedParticipantGroups = response.data.list
      })
      getParticipantAlarmPlans(id).then((response) => {
        this.selectedParticipantAlarmPlans = response.data.list
      })
      this.isEditable = false
      get2faStatus(this.selectedParticipant.login).then((res) => {
        if (res.data && res.data.status === 'NONE') {
          this.isSetResetToggle = true
        } else {
          this.isSetResetToggle = false
        }
      }).catch(() => {
        console.log('no 2fa for: ' + this.selectedParticipant.login)
      })
    },
    addNewMediaItem() {
      this.selectedParticipant.media.push({
        id: '_' + this.selectedParticipant.media.length,
        active: true,
        callingDeviceType: this.mediaDeviceTypes[0].callingDeviceType,
        callingNumber: null,
        acknowledgeTime: 1,
        rank: 1,
        participantId: this.selectedParticipant.id,
        message: this.mediaDeviceTypes[0].message,
        version: 0
      })
    },
    validateAccessToDesktopClient() {
      this.isValidAccessToDesktopClient = ((this.selectedParticipant.login && this.selectedParticipant.login.length > 0)
          && ((this.selectedParticipant._confirmPassword && this.selectedParticipant._confirmPassword.length > 0) && (this.selectedParticipant.password && this.selectedParticipant.password.length > 0))
          && this.linkedUserGroupId)
    },
    processSetSelectedParticipant(data) {
      this.selectedParticipant = data
      this.selectedParticipant._password = this.selectedParticipant.password
      this.selectedParticipant.password = null
      if (this.selectedParticipant.userGroupId && this.selectedParticipant.userGroupId !== null) {
        this.selectedParticipant.userGroup = this.userGroups.find(obj => {
          return obj.id === this.selectedParticipant.userGroupId
        })
        this.findUserGroup(this.selectedParticipant.userGroupId)
      } else {
        this.originalUserGroupObject = null
      }
      if (this.selectedParticipant.linkedUser && this.selectedParticipant.linkedUser.userGroupId && this.selectedParticipant.linkedUser.userGroupId !== null) {
        this.linkedUserGroupId = this.selectedParticipant.linkedUser.userGroupId
        this.selectedParticipant.linkedUser.userGroup = this.userGroups.find(obj => {
          return obj.id === this.selectedParticipant.linkedUser.userGroupId
        })
        this.findLinkedUserGroup(this.selectedParticipant.linkedUser.userGroupId)
      } else {
        this.linkedUserGroupId = null
        this.linkedUserGroupObject = null
      }
      this.originalParticipant = Object.assign({}, this.selectedParticipant)
    },
    getParticipants() {
      if (this.participantFilterValue === '' || this.participantFilterValue.length > 2) {
        this.participants = []
        if (this.listFiltersEnabled) {
          this.applyListFilters()
        } else {
          getList('PARTICIPANT', this.currentPage, this.participantFilterValue, this.participantTableRows, this.orgId, this.childFilter, 'surname').then((response) => {
            console.log('participants response', response);
            this.participants = response.data.list
            this.totalRecords = response.data.count
            // this.participants.sort((a, b) => (a.surname + ' ' + a.name).localeCompare(b.surname + ' ' + b.name))
            this.loading = false;
          })
        }
      }
    },
    getParticipantGroups(orgId = 1) {
      getList('PARTICIPANT_GROUP', 0, this.participantFilterValue, 1000, orgId).then((response) => {
        this.participantGroups = response.data.list.map(item => {
          const container = {};
          container.value = item.id;
          container.name = item.name
          // container.name = item.orgName + ' > ' + item.name
          return container;
        }, this)
        this.participantGroups.sort((a, b) => a.name.localeCompare(b.name))
        this.loading = false
      })
    },
    selectParticipantGroup() {
      getParticipantGroup(this.newParticipant.participantGroupId).then((response) => {
        this.selectedParticipantGroup = response.data
        this.targetGroups = response.data.callGroups.map(item => {
          const container = {};
          container.value = item.id;
          container.name = item.name
          return container;
        }, this)
      }, this)
    },
    editParticipant() {
      this.selectParticipant(this.selectedParticipant.id, true)
    },
    saveParticipant(mergeToUser = false) {
      if (!this.selectedParticipant.name || !this.selectedParticipant.surname || !this.selectedParticipant.orgId) {
        this.$root.showMessage('Please, specify name, surname and organization.', 'error')
        return false
      }

      if (this.selectedParticipant.login && this.selectedParticipant.login.length) {
        const participant = this.participants.find(item => item.id !== this.selectedParticipant.id && item.login === this.selectedParticipant.login)
        if (participant) {
          this.$root.showMessage('The specified login is already taken. Please, choose other.', 'error')
          return false
        }
      }

      if (this.selectedParticipant.media.find(item => item.callingDeviceType == 'IOS_APP')) {
        if ((!this.selectedParticipant.password || !this.selectedParticipant.password.length) && (!this.selectedParticipant._password || !this.selectedParticipant._password.length)) {
          this.$root.showMessage('Please, specify the password for Mobile App access.', 'error')
          return false
        }

        if (this.selectedParticipant.password && this.selectedParticipant.password.length) {
          if (!this.selectedParticipant.login || !this.selectedParticipant.login.length) {
            this.$root.showMessage('Please, specify the login for Mobile App access.', 'error')
            return false
          }
        }

        if (!this.originalUserGroupObject || !this.originalUserGroupObject.generalPermissions ||
            !Array.isArray(this.originalUserGroupObject.generalPermissions) ||
            !this.originalUserGroupObject.generalPermissions.includes('MOBILE_APP') ||
            !this.originalUserGroupObject.generalPermissions.includes('ALARM_BUTTON')) {
          this.$root.showMessage('Participant has no Mobile App access and Alarm Button roles. Please, select role with Mobile App access and Alarm Button permissions.', 'error')
          return false
        }
      }

      this.selectedParticipant.media = this.selectedParticipant.media.filter(item => item.callingDeviceType && item.callingDeviceType.length)
      this.selectedParticipant.media.map(function (item) {
        if (item.callingDeviceType == 'IOS_APP') {
          item.callingNumber = this.selectedParticipant.login
        }
        return item;
      }, this)

      if (this.selectedParticipant.media.find(item => !item.callingNumber || !item.callingNumber.length)) {
        this.$root.showMessage('Calling number may not be empty.', 'error')
        return false
      }

      this.expandedTreeTableKeys = {};

      let data = Object.assign({}, this.selectedParticipant)
      if (!data.password || !data.password.length) {
        data.password = data._password
      }
      delete data._password
      delete data._confirmPassword

      data.media.map((item) => {
        if (item.id.startsWith('_')) {
          delete item.id
        }
        return item
      })

      if (this.validatePassword() === false) {
        return false
      }

      return this.$refs.orgLink.moveToOrg((increaseVersion) => {
        if (increaseVersion) data.version++
        return updateParticipant(data).then((response) => {
          if (mergeToUser) {
            let data = Object.assign({}, response.data)
            data.userGroupId = this.linkedUserGroupId


            this.processSetSelectedParticipant(response.data)
            this.getParticipants()

            mergeParticipantToUser(data).then((response) => {
              this.processSetSelectedParticipant(response.data)
              this.isEditable = false
            }).catch((error) => {
              console.log(error)
              let data = error && error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
              this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
            })
          } else {
            this.saveLinkedUser(response.data)
            this.getParticipants()
            this.isEditable = false
          }
        }).catch((error) => {
          console.log(error)
          let data = error.response && error.response.data ?
              error.response.data instanceof Object ?
                  error.response.data :
                  JSON.parse(error.response.data) :
              {}
          this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
        })
      })
    },
    saveLinkedUser(participantData) {
      if (this.selectedParticipant.linkedUser) {
        getUser(this.selectedParticipant.linkedUser.id).then((response) => {
          let user = response.data
          user.userGroupId = this.linkedUserGroupId
          user.name = this.selectedParticipant.name
          user.surname = this.selectedParticipant.surname
          user.login = this.selectedParticipant.login
          user.userGroupId = this.selectedParticipant.userGroupId
          user.orgId = this.selectedParticipant.orgId
          user.personalId = this.selectedParticipant.personalNumber
          if (this.selectedParticipant.password && this.selectedParticipant.password.length) {
            user.password = this.selectedParticipant.password
          }
          updateUser(user).then((response) => {
            if (participantData.linkedUser && participantData.linkedUser.id == response.data.id) {
              participantData.linkedUser = response.data
            }
            this.processSetSelectedParticipant(participantData)
            this.getParticipants()
            this.isEditable = false
          }).catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        }).catch((error) => {
          console.log(error)
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
        })
      }
    },

    getUserGroups() {
      getList('USERGROUP', 0, '', 10000).then((response) => {
        this.userGroups = response.data.list
        this.currentUGPage = response.data.currentPage
        this.totalUGRecords = response.data.count
        this.loading = false
      })
    },
    removeMedia(data) {
      this.selectedParticipant.media.splice(data.index, 1);
    },
    initNewParticipantDialog(selected = false) {
      if (selected) {
        cloneParticipant(this.selectedParticipant).then((response) => {
          this.newParticipant = response.data
          this.newParticipant.personalNumber = null
          this.newParticipant.login = null
          this.newParticipant.password = null
          this.newParticipant.version = 0
          this.newParticipantDialog = true
        })
      } else {
        this.newParticipantDialog = true
      }
      this.getParticipantGroups(this.newParticipant.orgId)
    },
    createParticipant() {
      if (!this.newParticipant.orgId) {
        this.newParticipant.orgId = localStorage.getItem('orgId')
      }
      if (!this.newParticipant.name || !this.newParticipant.surname || !this.newParticipant.orgId || this.newParticipant.name.length === 0) {
        this.$root.showMessage(this._t('name_required'), 'error')
        return false
      }
      if (!this.newParticipant.personalNumber || !this.newParticipant.personalNumber.length) {
        this.newParticipant.personalNumber = null
      }
      createParticipant(this.newParticipant)
          .then((response) => {
            if (this.selectedParticipantGroup && this.newParticipant.participantGroupId && this.newParticipant.participantTargetGroupId) {
              let group = this.selectedParticipantGroup.callGroups.find(item => item.id == this.newParticipant.participantTargetGroupId)
              group.members.push({
                title: this.newParticipant.name + ' ' + this.newParticipant.surname,
                participantId: response.data.id,
                participantGroupId: null,
                planGroupId: this.newParticipant.participantTargetGroupId,
                orderId: group.members.length,
                version: 0
              })

              updateParticipantGroup(this.selectedParticipantGroup)
            }

            this.getParticipants()
            const that = this
            setTimeout(function () {
              that.selectParticipant(response.data.id)
              that.newParticipantDialog = false
              that.dataTabs = true
              that.isEditable = false
            }, 1000);
          }).catch((error) => {
        console.log(error)
        this.$root.showMessage(error.response.data.reason, 'error')
      })

    },
    deleteParticipant() {
      this.deleteParticipantDialog = false
      deleteParticipant(this.selectedParticipant.id).then(() => {
        if (this.selectedParticipant.linkedUser && this.selectedParticipant.linkedUser.id) {
          this.deleteSystemAccess()
        }

        this.selectedParticipant = {
          id: null,
          name: '',
          surname: '',
          login: null,
          personalNumber: null,
          password: null,
          language: null,
          userGroupId: null,
          userGroup: {
            name: ''
          },
          media: []
        }
        this.dataTabs = false
        this.getParticipants()
      }).catch((error) => {
        console.log(error)
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ?? 'Error deleting participant'), 'error')
      })
    },
    getDeviceList() {
      deviceList().then((response) => {
        this.mediaDeviceTypes = response.data
      })
    },
    findUserGroup(id) {
      findUserGroup(id).then((response) => {
        this.originalUserGroupObject = this.selectedParticipant.userGroup = response.data;
        this.originalUserGroupObject.accessRightsMapped = this.originalUserGroupObject.accessRights.map(item => {
          const container = {data: {}};
          container.data.name = item.treeNode.name;
          this.companyId = item.treeNode.companyId
          for (const right of item.rights) {
            container.data[right.category] = right.value
          }
          container.leaf = !item.hasChildren
          container.key = item.treeNode.id
          return container;
        })
        this.getUserGroupOrganizations()
      })
    },
    findLinkedUserGroup(id) {
      findUserGroup(id).then((response) => {
        this.linkedUserGroupObject = this.selectedParticipant.linkedUser.userGroup = response.data;
        this.linkedUserGroupObject.accessRightsMapped = this.linkedUserGroupObject.accessRights.map(item => {
          const container = {data: {}};
          container.data.name = item.treeNode.name;
          this.companyId = item.treeNode.companyId
          for (const right of item.rights) {
            container.data[right.category] = right.value
          }
          container.leaf = !item.hasChildren
          container.key = item.treeNode.id
          return container;
        })
      })
    },
    getUserGroupOrganizations() {
      getUGTree(this.originalUserGroupObject.orgId).then((response) => {
        this.originalOrganisationsObject.push(response.data)
        response.data = [response.data]
        this.organizations = response.data.map(item => {
              return this.mapAccessRights(item);
            }
        )
      })
      this.$forceUpdate()
    },
    getAccessRights() {
      this.accessRights = []
      getAccessRights().then((response) => {
        const groups = response.data
        for (const group in groups) {
          this.accessRights = this.accessRights.concat(groups[group])
        }
      });
    },
    getPermissions() {
      getPermissions().then((response) => {
        this.globalPermissions = response.data
      });
    },
    getAdminPermissions() {
      getAdminPermissions().then((response) => {
        this.adminPermissions = response.data
      });
    },
    mapAccessRights(item) {
      const container = {
        data: {
          rights: []
        }
      };
      container.data.name = item.name;

      for (const key of Object.values(this.accessRights)) {
        container.data.rights[key] = null
      }

      const accessRights = this.originalUserGroupObject.accessRightsMapped
      for (const accessRight of accessRights) {
        if (accessRight.key === item.id) {
          container.data.rights = accessRight.data
        }
      }
      container.leaf = !item.hasChildren
      container.key = item.id;
      return container;
    },
    formatTitle(title) {
      const labelsWithPrefix = ['REPORTS', 'MANUAL_CALL', 'ALARM_EVENT_RULE', 'INSTRUCTION_FILES'];
      const labelsFromMenu = ['IMPORT'];
      if (!title) return ''
      if (labelsWithPrefix.indexOf(title) !== -1) {
        title = 'label_' + title.toLowerCase()
      }
      if (labelsFromMenu.indexOf(title) !== -1) {
        title = 'menu.' + title.toLowerCase()
      }
      if (title === 'NONE') {
        title = '_NONE'
      } else if (title === 'NULL') {
        title = 'label_nil'
      }
      return this._t(title)
    },
    appendNode(item) {
      getTree(item.key).then((response) => {
        for (const org of response.data) {
          this.originalOrganisationsObject.push(org)
        }
        item.children = response.data.map(item => {
          return this.mapAccessRights(item);
        })
      })
    },
    validateMobileAccess() {
      getParticipantByLogin(this.selectedParticipant.login).then((response) => {
        if (response.data && response.data.id && response.data.id != this.selectedParticipant.id) {
          this.$root.showMessage('Selected username is already taken. Please, specify the different one.', 'error')
        } else {
          if (this.validatePassword()) {
            this.addMobileAccessDialog = false
            if (!this.selectedParticipant.media.find(item => item.callingDeviceType == 'IOS_APP')) {
              this.selectedParticipant.media.push({
                id: '_' + this.selectedParticipant.media.length,
                active: true,
                callingDeviceType: 'IOS_APP',
                message: this.mediaDeviceTypes.find(item => item.callingDeviceType == 'IOS_APP').message,
                callingNumber: null,
                acknowledgeTime: 1,
                rank: 1,
                participantId: this.selectedParticipant.id,
                version: 0
              }, this)
            }
          }
        }
      })
    },
    validateSystemAccess() {
      getParticipantByLogin(this.selectedParticipant.login).then((response) => {
        if (response.data && response.data.id && response.data.id != this.selectedParticipant.id) {
          this.$root.showMessage('Selected username is already taken. Please, specify the different one.', 'error')
        } else {
          getUserByLogin(this.selectedParticipant.login).then((response) => {
            if (response.data) {
              this.$root.showMessage('Selected username is already taken. Please, specify the different one.', 'error')
            } else {
              if (this.validatePassword() === true) {
                this.saveParticipant(true)
                this.addSystemAccessDialog = false
                this.addSystemAccessDialogForceNew = false
              }
            }
          })
        }
      })
    },
    validatePassword() {
      if (this.selectedParticipant.login && (!this.selectedParticipant._password && (!this.selectedParticipant.password || this.selectedParticipant.password.length === 0))) {
        this.$root.showMessage('Password cannot be empty.', 'error')
        return false
      }

      if (!this.selectedParticipant.password || !this.selectedParticipant.password.length) {
        return true
      }

      if (this.selectedParticipant.password !== this.selectedParticipant._confirmPassword) {
        this.$root.showMessage('Password and Confirm Password should be the same.', 'error')
        return false
      }
      const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\'$ %^&*()_+=:;"?])(?=.{8,})/
      // const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})')

      if (!strongRegex.test(this.selectedParticipant.password)) {
        this.$root.showMessage(this._t('label_password_validation'), 'error')
        return false
      }

      return true
    },
    toGroup(id) {
      router.push(`/participant-groups/${id}`)
    },
    toAlarmPlan(id) {
      router.push(`/alarm-plans/${id}`)
    },
    mediaDeviceTypesChange(event, data) {
      const device = this.mediaDeviceTypes.find(item => item.message === event.value)
      if (device) {
        data.callingDeviceType = device.callingDeviceType
      }

      if (data.callingDeviceType == 'IOS_APP') {
        if (!this.selectedParticipant.login || !this.selectedParticipant.login.length) {
          this.$root.showMessage('Participant has no username. Please, set username, password and role.', 'error')
          this.addMobileAccessDialog = true
          return false
        }

        if (!this.originalUserGroupObject || !this.originalUserGroupObject.generalPermissions ||
            !Array.isArray(this.originalUserGroupObject.generalPermissions) ||
            !this.originalUserGroupObject.generalPermissions.includes('MOBILE_APP') ||
            !this.originalUserGroupObject.generalPermissions.includes('ALARM_BUTTON')) {
          this.$root.showMessage('Participant has no Mobile App access and Alarm Button roles. Please, select role with Mobile App access and Alarm Button permissions.', 'error')
          this.addMobileAccessDialog = true
          return false
        }
      }
    },
    initDeleteMobileAccess() {
      this.deleteMobileAccessDialog = true;
      // if (this.selectedParticipant.media.find(item => item.callingDeviceType == 'IOS_APP')) {
      //   this.deleteMobileAccessDialog = true;
      // } else {
      //   this.deleteMobileAccess()
      // }
    },
    deleteMobileAccess() {
      this.selectedParticipant.login = null
      this.selectedParticipant.password = null
      this.selectedParticipant.userGroupId = null
      this.selectedParticipant.media = this.selectedParticipant.media.filter(item => item.callingDeviceType != 'IOS_APP')
      this.deleteMobileAccessDialog = false
    },
    cancelAddMobileAccess() {
      this.deleteMobileAccess()
      this.addMobileAccessDialog = false
    },
    cancelAddSystemAccess() {
      this.addSystemAccessDialog = false
      this.addSystemAccessDialogForceNew = false
    },
    deleteSystemAccess() {
      deleteUser(this.selectedParticipant.linkedUser.id).then(() => {
        delete this.selectedParticipant.linkedUser
        this.linkedUserGroupObject = null
        this.deleteSystemAccessDialog = false
      }).catch((error) => {
        console.log(error)
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ?? 'Error deleting linked user'), 'error')
      })
    },
    initAddSystemAccessDialog() {
      this.addSystemAccessDialogForceNew = ((this.selectedParticipant.login && this.selectedParticipant.login.length > 0)
          && (this.selectedParticipant.password && this.selectedParticipant.password.length > 0)
          && this.selectedParticipant.userGroupId)

      if (this.selectedParticipant.login && this.selectedParticipant.login.length) {
        getUserByLogin(this.selectedParticipant.login).then((response) => {
          if (response.data) {
            this.$root.showMessage('Selected username is already taken. Please, specify the different one.', 'error')
            // this.addSystemAccessDialogForceNew = true
          } else {
            if ((!this.selectedParticipant._password || !this.selectedParticipant._password.length)) {
              this.$root.showMessage('You need to specify the password for enabling desktop access.', 'error')

              this.isValidAccessToDesktopClient = false
            }
          }

          this.addSystemAccessDialog = true
        })
      } else {
        this.addSystemAccessDialog = true
      }
    },
    toUser(id) {
      router.push(`/users/${id}`)
    },
    toUserGroup(id) {
      router.push(`/user-groups/${id}`)
    },
    onAssignRotationalCalendar() {
      this.rotationalCalendar = this.selectedRotationalCalendar
      this.selectedParticipant.rotationalCalendarId = this.rotationalCalendar.id ?? null;
      this.saveParticipant();
      this.setRotationalIsEditable(false);
      this.callRotationalCalendarById(this.rotationalCalendar.id);
    },
    onUnAssignRotationalCalendar() {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.selectedParticipant.rotationalCalendarId = null;
          this.saveParticipant();
          this.setRotationalIsEditable(false);
          this.rotationalCalendar = {
            name: '',
            description: '',
            validFrom: ''
          };
          this.callRotationalCalendarById(null);
        },
        reject: () => {
        }
      });
    },
    onCreateRotationalCalendar() {
      this.createEmptyCalendar({orgId: this.orgId});
      this.getRotationalCalendar.name = this.rotationalCalendar.name;
      this.getRotationalCalendar.description = this.rotationalCalendar.description;
      this.getRotationalCalendar.validFrom = this.rotationalCalendar.validFrom;
      console.log(this.getRotationalCalendar.validFrom)
      this.createRotationalCalendar()
          .then(() => {
            if (this.getRotationalCalendar.id) {
              this.selectedParticipant.rotationalCalendarId = this.getRotationalCalendar.id ?? null;
              this.saveParticipant()
              this.rotationalCalendar = {
                name: '',
                description: '',
                validFrom: ''
              };
              this.setRotationalIsEditable(false);
              this.callRotationalCalendarById(this.getRotationalCalendar.id);
            }
          });
    },
    newEvent() {
      // const date = event.target.getAttribute('data-date');
      // console.log(date);
      this.createEmptyEvent();
      this.newEventDialog = true;
    },
    createNewEvent() {
      this.callCreateUpdateEvent(this.selectedParticipant.id)
          .then(isSuccessful => {
            if (isSuccessful) {
              this.newEventDialog = false;
            }
          })
          .catch(error => {
            let data = error.response && error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            if (data.reason === 'ACK_TIMEOUT_CALLING_NUMBER_REQUIRED') {
              data.reason = 'Calling number may not be null';
            }
            this.setEvent({
              ...this.getEvent,
              endDate: moment(this.getEvent.endDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
              startDateSelected: moment(this.getEvent.startDateSelected, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY'),
              endDateSelected: moment(this.getEvent.endDateSelected, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY'),
              startTime: moment(this.getEvent.startTime, 'HH:mm:ss').format('HH:mm'),
              endTime: moment(this.getEvent.endTime, 'HH:mm:ss').format('HH:mm'),
            });
            this.$root.showMessage(data.reason ? ': ' + data.reason : '', 'error')
          });
    },
    clickEvent(event) {
      const eventId = event.target.getAttribute('data-id');
      if (eventId) {
        this.setParticipantIsEditable(true);
        this.selectEvent(eventId);
        this.newEventDialog = true;
      }
    },
    removeSelectedEvent() {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.callRemoveEvent({participantId: this.selectedParticipant.id, sourceEventId: this.getEvent.sourceEventId})
              .then(isSuccessful => {
                if (isSuccessful) {
                  this.setEvent(null);
                  this.newEventDialog = false;
                }
              })
              .catch(error => {
                let data = error.response && error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
                this.$root.showMessage((data.reason ?? 'Error deleting event'), 'error')
              });
        },
        reject: () => {
          // nothing to do
        }
      });
    },
    clickDay() {
      // const date = event.target.getAttribute('data-date');
      // console.log(date);
      this.createEmptyEvent();
      this.newEventDialog = true;
    },
    applyListFilters(reset = false) {
      this.listFiltersEnabled = true
      if (reset === true) {
        this.getParticipants()
      } else {
        getListExtended({"participantMultiFilter": this.listFilters}, 'PARTICIPANT', this.currentPage, '', this.participantTableRows, this.orgId, this.childFilter, 'surname').then((response) => {
          this.participants = response.data.list
          this.totalRecords = response.data.count
          this.loading = false;
        })
      }
      this.filtersDialog = false
    },
    resetListFilters() {
      this.listFilters = {};
      this.filtersDialog = false;
      this.applyListFilters();
      this.listFiltersEnabled = false;
    },
    handleCheckRow(name) {
      const foundedValue = this.alarmParticipantsValues.find(({value}) => value === name)
      return !!foundedValue?.checked?.length;
    },
  },
  watch: {
    newParticipantDialog() {
      if (!this.newParticipantDialog) {
        this.newParticipant = {
          name: '',
          surname: '',
          login: '',
          password: null,
          language: null,
          orgName: '...',
          orgId: null,
          personalNumber: null,
          type: 'CUSTOM',
          version: 0,
          participantGroupId: null,
          participantTargetGroupId: null
        }
        return
      }
    },
    childFilter() {
      this.getParticipants()
    },
    rotationalCalendar(value) {
      if (typeof value === 'string') {
        this.callRotationalCalendars({'filterValue': value, 'tableRows': 100});
      }
    },
    selectedParticipantItem(value) {
      if (value === null) {
        this.selectedParticipant.id = null
        return
      }
      this.selectParticipant(value.id)
    },
    allDay: function () {
      if (this.allDay) {
        this.getEvent.startTime = '00:00';
        this.getEvent.endTime = '23:59';
      }
    },
    isEditable() {
      this.setRotationalIsEditable(this.isEditable);
      this.setParticipantIsEditable(this.isEditable);
    },
    activeTab(value) {
      this.$router.push({
        query: {
          active_tab: value
        }
      });
    },
    '$route.query.active_tab'(value) {
      if (value >= 0) {
        this.activeTab = parseInt(value);
      }
    },
    '$route.params.id'() {
      // if (value.length > 0) {
      // this.selectParticipant(value)
      // }
      this.calledWarning = false
    },
    participantFilterValue(value) {
      this.getParticipants(value)
    },
    'selectedParticipant.login'() {
      this.validateAccessToDesktopClient()
    },
    'selectedParticipant.password'() {
      this.validateAccessToDesktopClient()
    },
    'selectedParticipant._confirmPassword'() {
      this.validateAccessToDesktopClient()
    },
    linkedUserGroupId() {
      this.validateAccessToDesktopClient()
    }
  },
  mounted() {
    this.languages = [
      {value: 'ENGLISH', label: this._t('ENGLISH')},
      {value: 'GERMAN', label: this._t('GERMAN')},
      // {value: 'FRENCH', label: this._t('FRENCH')},
      // {value: 'ITALIAN', label: this._t('ITALIAN')},
      {value: 'CZECH', label: this._t('CZECH')},
      // {value: 'GREEK', label: this._t('GREEK')},
      // {value: 'SPANISH', label: this._t('SPANISH')}
    ];
    this.newParticipant.orgId = localStorage.getItem('orgId');
    this.selectedParticipant = {
      id: null,
      name: '',
      surname: '',
      login: '',
      personalNumber: '',
      language: '',
      userGroupId: null,
      userGroup: {
        name: ''
      }
    }
    this.getParticipants()
    this.getParticipantGroups()
    this.getDeviceList()
    this.checkOrgId()
    this.getUserGroups()
    this.getAdminPermissions()
    this.getAccessRights()
    this.getPermissions()
    if (this.$route.params.id) {
      this.selectParticipant(this.$route.params.id)
    }
    if (this.$route.query.active_tab) {
      this.activeTab = parseInt(this.$route.query.active_tab)
    }
    this.callRotationalCalendars({'tableRows': 100});

    getUserColumns().then((userRes) => {
      if (userRes.data) {
        for (const [key, value] of Object.entries(userRes.data)) {
          if (key === 'alarmParticipantsItems') this.alarmParticipantsValues = value
        }
      } else {
        getOrganizationColumns().then((res) => {
          if (res.data) {
            for (const [key, value] of Object.entries(res.data)) {
              if (key === 'alarmParticipantsItems') this.alarmParticipantsValues = value
            }
          }
        }).catch((err) => console.log('error', err))
      }
    }).catch((err) => console.log('error', err))
  },

  computed: {
    ...mapGetters({
      'getRotationalCalendars': 'participantCalendar/getCalendars',
      'getRotationalCalendar': 'participantCalendar/getCalendar',
      'getParticipantCalendar': 'participant/getCalendar',
      'getEvent': 'participant/getEvent',
      'getDevices': 'devices/getDevices',
      'getIsEditable': 'participant/getIsEditable',
      'getRepeatTypes': 'participant/getRepeatTypes',
    }),
    repeatEveryTitle: function () {
      if (this.getEvent && this.getEvent.repeatType) {
        switch (this.getEvent.repeatType) {
          case RepeatTypeEnum.DAILY:
            return this._t('label_day');
          case RepeatTypeEnum.WEEKDAYS:
          case RepeatTypeEnum.WEEKENDS:
          case RepeatTypeEnum.WEEKLY:
            return this._t('label_week');
          case RepeatTypeEnum.MONTHLY:
            return this._t('label_month');
          case RepeatTypeEnum.YEARLY:
            return this._t('label_year');
        }
      }
      return '';
    },
    weekDayEnum: () => WeekDayEnum,
    repeatTypeEnum: () => RepeatTypeEnum,
  },
}
</script>

<style lang="scss" scoped>
.text {
  font-size: 12px;
  padding: 0;
}

::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-datatable .p-column-filter {
  //display: ee;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable) {
  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
    background-color: #f8f9fa;
    padding-left: 10px !important;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 5px;
    font-weight: 1500;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;

      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid white;

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;

          .p-column-title {

            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-datatable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #C63737;
}

::v-deep(.p-treetable) {
  .p-treetable-tbody > tr > td {
    padding: 5px;
  }

  .p-treetable-thead > tr > th {
    padding: 5px;
    border: none;
  }

}

.customer {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customer td, .customers th {
  border: none;
  padding: 5px;
}

.customer tr:nth-child(even) {
  //background-color: #f2f2f2;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disable-th th {
  display: none;
}

p-tabview-nav-link {
  color: #82B541;
  border-color: #82B541;
}

.p-highlight {
  background-color: #82B541 !important;
}

.stripedBg {
  background-color: #f9f9f9;
}
</style>
